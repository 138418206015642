import React from "react";
import { Link } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import {
  listeannees,
  listepcs,
  listepays,
  listeenseignementsdistance,
  listesituations,
  listediplomes,
  listeprepas,
  listebacs,
  listeSpecialites,
  listedepartements,
  listecommunes,
  listeniveaux
} from "../config/referentielSiseScolege";
import { formations } from "../config/formations";
import SiseRecapitulatif from "../components/SiseRecapitulatif";
import SiseIntro from "../components/SiseIntro";
import SiseEtapeTitre from "../components/SiseEtapeTitre";
import APIsEndpoints from "../config/apisEndpoints";
import APIs from "../config/apis";
import { getAccessToken } from '../authProvider';


class EnqueteSise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: null,
      data: null,
      saveSuccess: false,
      saveError: false,
      incomplete: false,
      authorized: true,
      bornAbroad: false,
      validationStep1: [{ communeNaissance: null, nationalite: null, numeroIne: null }],
      validationStep2: [{ anneeEntree: null, niveauFormation: null }],
      validationStep3: [
        {
          situationAnneePrecedente: null,
          dernierDiplome: null,
          typeBac: null,
          anneeObtentionBac: null,
          departementObtentionBac: null
        }
      ],
      displayDepartementObtentionBac: true,
      displayBacSpecialites: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSaveStep1 = this.handleSaveStep1.bind(this);
    this.handleSaveStep2 = this.handleSaveStep2.bind(this);
    this.searchCommuneNaissance = this.searchCommuneNaissance.bind(this);
    this.handleBornAbroad = this.handleBornAbroad.bind(this);
    this.handleGraduatedAbroad = this.handleGraduatedAbroad.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.handlePaysNaissanceChange = this.handlePaysNaissanceChange.bind(this);
    this.inputNationaliteFocus = React.createRef();
    this.inputCommuneNaissanceFocus = React.createRef();
    this.inputPaysNaissanceFocus = React.createRef();
    this.inputAnneeEntreeFocus = React.createRef();
    this.inputNiveauFormationFocus = React.createRef();
    this.inputSituationAnneePrecedenteFocus = React.createRef();
    this.inputDernierDiplomeFocus = React.createRef();
    this.inputAnneeObtentionBacFocus = React.createRef();
    this.inputTypeBacFocus = React.createRef();
    this.inputDepartementObtentionBac = React.createRef();
  }

  async loadUser() {
    var backendAccessToken = await getAccessToken();
    fetch(
      APIs.find(o => o.app === "backEnd").url +
      APIsEndpoints.find(
        o => o.module === "enquetes" && o.feature === "sise"
      ).endpoint,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ` + backendAccessToken
        }
      }
    )
      .then(res => {
        if (res && res.ok) {
          return res.json();
        }
      })
      .then(res => {
        if (
          res.communeNaissanceCodePostal != null &&
          res.communeNaissanceCodePostal.startsWith("99")
        ) {
          this.setState({ bornAbroad: true });

          if (
            listepays.find(
              o =>
                o.code.toLowerCase() ===
                res.communeNaissanceCodePostal
                  .slice(2, res.communeNaissanceCodePostal.length)
                  .toLowerCase()
            ) != null
          ) {
            res.paysNaissance = listepays.find(
              o =>
                o.code.toLowerCase() ===
                res.communeNaissanceCodePostal
                  .slice(2, res.communeNaissanceCodePostal.length)
                  .toLowerCase()
            ).code;
          }
        } else if (res.communeNaissanceCodePostal != null) {
          var listecommunesFiltered = listecommunes.filter(
            o => o.code === res.communeNaissanceCodePostal
          );
          this.setState({ listecommunesFiltered });
        }
        if (
          res.departementObtentionBac != null &&
          res.departementObtentionBac.startsWith("099")
        ) {
          this.setState({ graduatedAbroad: true });
        }
        if (
          res.typeBac != null &&
          listebacs.find(
            o => o.code === res.typeBac && o.type === "Equivalence"
          ) && res.typeBac !== "38"
        ) {
          this.setState({ displayDepartementObtentionBac: false });
        } else {
          this.setState({ displayDepartementObtentionBac: true });
        }
        if (res.typeBac && res.typeBac ==="NBGE") {
          this.setState({ displayBacSpecialites: true });
        }
        this.setState({ data: res });
      });

  }

  async componentDidMount() {
    if (
      this.props.user != null &&
      this.props.user.jobTitle === "Etudiant" &&
      this.props.user
        .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel !=
      null
    ) {
      var formation = formations.find(
        o =>
          o.code ===
          this.props.user
            .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel
      );
      if (formation != null && formation.sise) {
        this.setState({ authorized: true });
      } else {
        this.setState({ authorized: false });
      }
    } else {
      this.setState({ authorized: false });
    }

  }

  searchCommuneNaissance = event => {
    var data = { ...this.state.data };
    data.inputCommuneNaissance = event.target.value;
    this.setState({ data });
    if (
      data.inputCommuneNaissance != null &&
      data.inputCommuneNaissance.length > 1
    ) {
      var listecommunesFiltered = listecommunes
        .filter(
          o =>
            o.intitule
              .toLowerCase()
              .replace(/[éèëê]/g, "e")
              .replace(/[àä]/g, "a")
              .replace(/ï/g, "i")
              .replace(/ö/g, "o")
              .replace(/[üù]/g, "u")
              .startsWith(
                data.inputCommuneNaissance
                  .toLowerCase()
                  .replace(/[éèëê]/g, "e")
                  .replace(/[àä]/g, "a")
                  .replace(/ï/g, "i")
                  .replace(/ö/g, "o")
                  .replace(/[üù]/g, "u")
              ) || o.cp.includes(data.inputCommuneNaissance)
        ).sort(function (a, b) {
          var nameA = a.intitule.toUpperCase();
          var nameB = b.intitule.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
    }
    this.setState({ listecommunesFiltered });
    if (listecommunesFiltered != null && listecommunesFiltered.length > 0) {
      data.communeNaissance = listecommunesFiltered[0].intitule;
      data.communeNaissanceCodePostal = listecommunesFiltered[0].code;
    } else {
      data.communeNaissance = null;
      data.communeNaissanceCodePostal = null;
    }
    this.setState({ data });
  };

  async handleSubmit() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    fetch(
      APIs.find(o => o.app === "backEnd").url +
      APIsEndpoints.find(
        o => o.module === "enquetes" && o.feature === "sise"
      ).endpoint,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ` + backendAccessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    ).then(res => {
      if (res && res.ok) {
        this.setState({ saveSuccess: true, step: null, data: null });
      } else {
        this.setState({ saveError: true });
      }
    });
  }

  async handleSaveStep1() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    var validationStep1 = this.state.validationStep1;
    //Vérification des champs
    if (data.communeNaissance === null || data.communeNaissance === "") {
      validationStep1.communeNaissance = false;
    } else {
      validationStep1.communeNaissance = true;
    }
    if (data.nationalite === null || data.nationalite === "") {
      validationStep1.nationalite = false;
    } else {
      validationStep1.nationalite = true;
    }
    if (data.numeroIne && data.numeroIne.length > 11) {
      validationStep1.numeroIne = false;
    } else {
      validationStep1.numeroIne = true;
    }
    //Focus sur le 1er champ en erreur
    if (!validationStep1.communeNaissance) {
      if (!this.state.bornAbroad) {
        this.inputCommuneNaissanceFocus.current.focus();
      } else {
        this.inputPaysNaissanceFocus.current.focus();
      }
      //const field = document.getElementById("inputCommuneNaissance");
      //field.scrollIntoView();
    } else if (!validationStep1.nationalite) {
      //const field = document.getElementById("nationalite");
      //field.scrollIntoView();
      this.inputNationaliteFocus.current.focus();
    }
    this.setState({ validationStep1 });
    //Enregistrement des champs si validation = ok
    if (!validationStep1.communeNaissance || !validationStep1.nationalite || !validationStep1.numeroIne) {
      this.setState({ incomplete: true });
    } else {
      fetch(
        APIs.find(o => o.app === "backEnd").url +
        APIsEndpoints.find(
          o => o.module === "enquetes" && o.feature === "sise"
        ).endpoint,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ` + backendAccessToken,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        }
      ).then(res => {
        if (res.ok) {
          this.setState({ step: 2 });
          window.scrollTo(0, 0);
        } else {
          this.setState({ saveError: true });
        }
      }).catch(ex => {
        console.log(ex);
      });
    }
  }

  async handleSaveStep2() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    var validationStep2 = this.state.validationStep2;
    if (data.anneeEntree === null || data.anneeEntree === "") {
      validationStep2.anneeEntree = false;
    } else {
      validationStep2.anneeEntree = true;
    }
    if (data.niveauFormation === null || data.niveauFormation === "") {
      validationStep2.niveauFormation = false;
    } else {
      validationStep2.niveauFormation = true;
    }
    //Focus sur le 1er champ en erreur
    if (!validationStep2.anneeEntree) {
      this.inputAnneeEntreeFocus.current.focus();
    } else if (!validationStep2.niveauFormation) {
      this.inputNiveauFormationFocus.current.focus();
    }
    this.setState({ validationStep2 });
    if (!validationStep2.anneeEntree || !validationStep2.niveauFormation) {
      this.setState({ incomplete: true });
    } else {
      data = this.state.data;
      fetch(
        APIs.find(o => o.app === "backEnd").url +
        APIsEndpoints.find(
          o => o.module === "enquetes" && o.feature === "sise"
        ).endpoint,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ` + backendAccessToken,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        }
      ).then(res => {
        if (res.ok) {
          this.setState({ step: 3 });
          window.scrollTo(0, 0);
        } else {
          this.setState({ saveError: true });
        }
      });
    }
  }

  async handleSaveStep3() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    var validationStep3 = this.state.validationStep3;
    if (
      data.situationAnneePrecedente === null ||
      data.situationAnneePrecedente === ""
    ) {
      validationStep3.situationAnneePrecedente = false;
    } else {
      validationStep3.situationAnneePrecedente = true;
    }
    if (data.dernierDiplome === null || data.dernierDiplome === "") {
      validationStep3.dernierDiplome = false;
    } else {
      validationStep3.dernierDiplome = true;
    }
    if (data.anneeObtentionBac === null || data.anneeObtentionBac === "") {
      validationStep3.anneeObtentionBac = false;
    } else {
      validationStep3.anneeObtentionBac = true;
    }
    if (data.typeBac === null || data.typeBac === "") {
      validationStep3.typeBac = false;
    } else {
      validationStep3.typeBac = true;

      if (data.typeBac === "NBGE") {
        validationStep3.bac_spe1 = data.bac_spe1 ? true : false;
        validationStep3.bac_spe2 = data.bac_spe2 ? true : false;
      }else{
        validationStep3.bac_spe1 = true;
        validationStep3.bac_spe2 = true;
      }
    }
    if (!this.state.displayDepartementObtentionBac) {
      switch (data.typeBac) {
        case "31":
          data.departementObtentionBac = "99"
          break;
        case "30":
        case "32":
        case "33":
        case "34":
        case "35":
        case "36":
        default:
          data.departementObtentionBac = "0";
          break;
      }
    }

    if (
      this.state.displayDepartementObtentionBac &&
      (data.departementObtentionBac === null ||
        data.departementObtentionBac === "")
    ) {
      validationStep3.departementObtentionBac = false;
    } else {
      validationStep3.departementObtentionBac = true;
    }
    //Focus sur le 1er champ en erreur
    if (!validationStep3.situationAnneePrecedente) {
      this.inputSituationAnneePrecedenteFocus.current.focus();
    } else if (!validationStep3.dernierDiplome) {
      this.inputDernierDiplomeFocus.current.focus();
    } else if (!validationStep3.anneeObtentionBac) {
      this.inputAnneeObtentionBacFocus.current.focus();
    } else if (!validationStep3.typeBac) {
      this.inputTypeBacFocus.current.focus();
    } else if (!validationStep3.departementObtentionBac) {
      this.inputDepartementObtentionBac.current.focus();
    }
    this.setState({ validationStep3 });
    if (
      !validationStep3.situationAnneePrecedente ||
      !validationStep3.dernierDiplome ||
      !validationStep3.anneeObtentionBac ||
      !validationStep3.typeBac ||
      !validationStep3.bac_spe1 ||
      !validationStep3.bac_spe2 ||
      !validationStep3.departementObtentionBac
    ) {
      this.setState({ incomplete: true });
    } else {
      fetch(
        APIs.find(o => o.app === "backEnd").url +
        APIsEndpoints.find(
          o => o.module === "enquetes" && o.feature === "sise"
        ).endpoint,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ` + backendAccessToken,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        }
      ).then(res => {
        if (res.ok) {
          this.setState({ saveSuccess: true });
        } else {
          this.setState({ saveError: true });
        }
      });
    }
  }

  handlePaysNaissanceChange = event => {
    if (event.target.value !== "Selectionner") {
      var data = { ...this.state.data };
      data.communeNaissanceCodePostal = "99" + event.target.value;
      data.communeNaissance = listepays
        .find(o => o.code === event.target.value)
        .intitule.toUpperCase();
      data.paysNaissance = event.target.value;
      this.setState({ data });
    } else {
      data = { ...this.state.data };
      data.communeNaissanceCodePostal = null;
      data.communeNaissance = null;
      data.paysNaissance = null;
      this.setState({
        data
      });
    }
  };

  handleBornAbroad = event => {
    var data = { ...this.state.data };
    data.inputCommuneNaissance = null;
    data.paysNaissance = null;
    data.communeNaissance = null;
    data.communeNaissanceCodePostal = null;
    this.setState({ bornAbroad: event.target.checked, data });
  };

  handleGraduatedAbroad = event => {
    var data = { ...this.state.data };
    data.departementObtentionBac = "099";
    this.setState({ graduatedAbroad: event.target.checked, data });
  };

  handleInputChange = event => {
    var data = { ...this.state.data };
    data[event.target.id] = event.target.value;
    this.setState({
      data
    });
  };

  handleSelectChange = event => {
    if (event.target.value !== "Selectionner") {
      var data = { ...this.state.data };
      if (event.target.id != null && event.target.id !== "") {
        data[event.target.id] = event.target.value;
        if (event.target.id === "typeBac") {
          if (
            listebacs.find(
              o => o.code === event.target.value && o.type === "Equivalence"
            ) != null && event.target.value !== "38"
          ) {
            this.setState({ displayDepartementObtentionBac: false });
            data.departementObtentionBac = null;
          } else {
            this.setState({ displayDepartementObtentionBac: true });
          }

          //affiche les dropdowns pour sélectionner ses spé pour le nouveau bac
          if (event.target.value === "NBGE") {
            this.setState({ displayBacSpecialites: true });
          }
          else {
            //si des spécialités sont saisies alors que l'on est pas/plus sur un nouveau bac général, 
            //alors on vide les spés
            if (data.bac_spe1) data.bac_spe1 = "";
            if (data.bac_spe2) data.bac_spe2 = "";
            if (this.state.displayBacSpecialites === true) {
              this.setState({ displayBacSpecialites: false });
            }
          }
        }
      } else {
        data[event.target.parentElement.id] = event.target.value;
        if (event.target.parentElement.id === "typeBac") {
          if (
            listebacs.find(
              o => o.code === event.target.value && o.type === "Equivalence"
            ) != null && event.target.value !== "38"
          ) {
            this.setState({ displayDepartementObtentionBac: false });
            data.departementObtentionBac = null;
          } else {
            this.setState({ displayDepartementObtentionBac: true });
          }
        }
      }
      this.setState({
        data
      });
    } else {
      data = { ...this.state.data };
      if (event.target.id != null && event.target.id !== "") {
        if (event.target.id === "typeBac") {
          this.setState({ displayDepartementObtentionBac: true });
          data.departementObtentionBac = null;
        }
        data[event.target.id] = null;
      } else {
        if (event.target.parentElement.id === "typeBac") {
          this.setState({ displayDepartementObtentionBac: true });
          data.departementObtentionBac = null;
        }
        data[event.target.parentElement.id] = null;
      }
      this.setState({
        data
      });
    }
  };

  handleCommuneNaissanceChange = event => {
    var data = { ...this.state.data };
    if (event.target.value !== "Selectionner") {
      var commune = listecommunes.find(o => o.code === event.target.value);
      data.communeNaissance = commune.intitule;
      data.communeNaissanceCodePostal = commune.cp;
    } else {
      data.communeNaissance = null;
      data.communeNaissanceCodePostal = null;
    }
    this.setState({
      data
    });
  };

  render() {
    if (!this.state.authorized) {
      return (
        <div
          className="toast toast--pigier mb-5 fade show"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <div>
              <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
              <Link className="ml-2" to="/">
                Revenir à l'accueil
              </Link>
            </div>
          </div>
        </div>
      );
    } else
     if (this.state.step === null) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.saveError}
            title="Oops !"
            type="error"
            text="Une erreur s'est produite. Si le problème persiste, merci de contacter votre assistant pédagogique."
            confirmButtonText="Revenir au formulaire"
            onConfirm={() => this.setState({ saveError: false })}
          />
          <div className="block" id="intro">
            <SiseIntro />
            <Link to="/" className="col-sm-6 btn-group">
              <span className="btn btn--ghost" id="prev">
                Retour à l'accueil
              </span>
            </Link>
            <button
              className="col-sm-6 btn-group"
              onClick={() => {
                this.loadUser();
                this.setState({ step: 1 });
              }}
            >
              <span className="btn" id="next">
                Répondre
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 1) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.incomplete}
            title="Données incomplètes !"
            type="warning"
            text="Vos données sont incomplètes. Merci de remplir l'ensemble des champs obligatoires marqués d'une étoile."
            onConfirm={e => {
              this.setState({ incomplete: false });
            }}
            confirmButtonText="Retour au formulaire"
            confirmButtonColor="#00437e"
          />
          <div className="block" id="step1">
            <SiseEtapeTitre title="Informations personnelles" step={1} />
            <div className={this.state.validationStep1 && this.state.validationStep1.numeroIne === false ? "form-group mt-5 error" : "form-group mt-5"}>
              <label>INE - RNIE</label>
              {this.state.validationStep1 && this.state.validationStep1.numeroIne === false ? <span className="error-message">Le numéro INE doit obligatoirement faire 11 caractères ou moins.</span> : null}
              <input
                className="form-control"
                id="numeroIne"
                placeholder=""
                maxLength="11"
                value={this.state.data && this.state.data.numeroIne ? this.state.data.numeroIne : ""}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Prénom 2</label>
              <input
                type="text"
                className="form-control"
                id="prenom2"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.prenom2 != null
                    ? this.state.data.prenom2
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>Prénom 3</label>
              <input
                type="text"
                className="form-control"
                id="prenom3"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.prenom3 != null
                    ? this.state.data.prenom3
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label style={
                this.state.validationStep1.communeNaissance != null &&
                  !this.state.validationStep1.communeNaissance
                  ? { color: "red" }
                  : {}
              }
              >
                Lieu de naissance *
              </label>
              <br />
              <input
                type="checkbox"
                id="scales"
                name="scales"
                className=""
                checked={this.state.bornAbroad}
                onChange={this.handleBornAbroad}
              />
              <label style={{ marginLeft: "10px" }}>
                Je suis né à l'étranger
              </label>
              {this.state.bornAbroad === null || !this.state.bornAbroad ? (
                <>
                  <input
                    type="text"
                    className="form-control input_search"
                    id="inputCommuneNaissance "
                    aria-describedby="emailHelp"
                    ref={this.inputCommuneNaissanceFocus}
                    placeholder="Entrer les premières lettres ou le code postal de la commune"
                    value={
                      this.state.data != null &&
                        this.state.data.inputCommuneNaissance != null
                        ? this.state.data.inputCommuneNaissance
                        : ""
                    }
                    onChange={this.searchCommuneNaissance}
                  />

                  <select
                    className="selectpicker"
                    data-width="auto"
                    id="communeNaissance"
                    value={
                      this.state.data != null &&
                        this.state.data.communeNaissanceCodePostal != null
                        ? this.state.data.communeNaissanceCodePostal
                        : ""
                    }
                    onChange={this.handleCommuneNaissanceChange}
                  >
                    <option>Selectionner</option>
                    {this.state.listecommunesFiltered != null &&
                      this.state.listecommunesFiltered.length > 0
                      ? this.state.listecommunesFiltered
                        .sort(function (a, b) {
                          var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                          var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }

                          // names must be equal
                          return 0;
                        })
                        .map((o, key) => (
                          <option key={key} value={o.code}>
                            {o.intitule.toUpperCase() + " (" + o.cp + ")"}
                          </option>
                        ))
                      : ""}
                  </select>
                </>
              ) : (
                  <select
                    className="selectpicker"
                    data-width="auto"
                    id="paysNaissance"
                    value={
                      this.state.data != null &&
                        this.state.data.paysNaissance != null
                        ? this.state.data.paysNaissance
                        : ""
                    }
                    ref={this.inputPaysNaissanceFocus}
                    onMouseDown={this.handlePaysNaissanceChange}
                    onChange={this.handlePaysNaissanceChange}
                  >
                    <option>Selectionner</option>
                    {listepays
                      .filter(o => o.code !== "100")
                      .sort(function (a, b) {
                        var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                          return -1;
                        }
                        if (nameA > nameB) {
                          return 1;
                        }

                        // names must be equal
                        return 0;
                      })
                      .map((o, key) => (
                        <option key={key} value={o.code}>
                          {o.intitule.toUpperCase()}
                        </option>
                      ))}
                  </select>
                )}
            </div>
            <div className="form-group mt-5">
              <label
                style={
                  this.state.validationStep1.nationalite != null &&
                    !this.state.validationStep1.nationalite
                    ? { color: "red" }
                    : {}
                }
              >
                Nationalité *
              </label>
              <select
                className="selectpicker error"
                data-width="auto"
                id="nationalite"
                ref={this.inputNationaliteFocus}
                value={
                  this.state.data != null && this.state.data.nationalite != null
                    ? this.state.data.nationalite
                    : ""
                }
                style={
                  this.state.validationStep1.nationalite != null &&
                    !this.state.validationStep1.nationalite
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listepays
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>Pays de résidence</label>
              <select
                className="selectpicker"
                data-width="auto"
                id="paysResidence"
                value={
                  this.state.data != null &&
                    this.state.data.paysResidence != null
                    ? this.state.data.paysResidence
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listepays
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>PCS parent référent</label>
              <select
                className="selectpicker"
                data-width="auto"
                id="pcsParentReferent"
                value={
                  this.state.data != null &&
                    this.state.data.pcsParentReferent != null
                    ? this.state.data.pcsParentReferent
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listepcs
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>PCS de l'autre parent</label>
              <select
                className="selectpicker"
                data-width="auto"
                id="pcsParentAutre"
                value={
                  this.state.data != null &&
                    this.state.data.pcsParentAutre != null
                    ? this.state.data.pcsParentAutre
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listepcs
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <button
              className="col-sm-6 btn-group"
              onClick={() => {
                this.setState({ step: null });
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn btn--ghost" id="next">
                Retour à l'étape précédente
              </span>
            </button>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.handleSaveStep1()}
            >
              <span className="btn" id="next">
                Etape suivante
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.incomplete}
            title="Données incomplètes !"
            type="warning"
            text="Vos données sont incomplètes. Merci de remplir l'ensemble des champs obligatoires marqués d'une étoile."
            onConfirm={e => {
              this.setState({ incomplete: false });
            }}
            confirmButtonText="Retour au formulaire"
            confirmButtonColor="#00437e"
          />
          <div className="block" id="infos">
            <SiseEtapeTitre title="Formation actuelle" step={2} />
            <div className="form-group">
              <label
                style={
                  this.state.validationStep2.anneeEntree != null &&
                    !this.state.validationStep2.anneeEntree
                    ? { color: "red" }
                    : {}
                }
              >
                Année d'entrée dans l'établissement *
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="anneeEntree"
                ref={this.inputAnneeEntreeFocus}
                value={
                  this.state.data != null && this.state.data.anneeEntree != null
                    ? this.state.data.anneeEntree
                    : ""
                }
                style={
                  this.state.validationStep2.anneeEntree != null &&
                    !this.state.validationStep2.anneeEntree
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listeannees.map((o, key) => (
                  <option key={key} value={o.code}>
                    {o.intitule}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>Enseignement à distance</label>
              <select
                className="selectpicker"
                data-width="auto"
                id="enseignementDistance"
                value={
                  this.state.data != null &&
                    this.state.data.enseignementDistance != null
                    ? this.state.data.enseignementDistance
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listeenseignementsdistance.map((o, key) => (
                  <option key={key} value={o.code}>
                    {o.intitule}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label style={
                this.state.validationStep2.niveauFormation != null &&
                  !this.state.validationStep2.niveauFormation
                  ? { color: "red" }
                  : {}
              }
              >
                Niveau dans la formation actuelle *
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="niveauFormation"
                ref={this.inputNiveauFormationFocus}
                value={
                  this.state.data != null &&
                    this.state.data.niveauFormation != null
                    ? this.state.data.niveauFormation
                    : ""
                }
                style={
                  this.state.validationStep2.niveauFormation != null &&
                    !this.state.validationStep2.niveauFormation
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listeniveaux.map((o, key) => (
                  <option key={key} value={o.code}>
                    {o.intitule}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="col-sm-6 btn-group"
              onClick={() => {
                this.setState({ step: 1 });
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn btn--ghost" id="next">
                Retour à l'étape précédente
              </span>
            </button>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.handleSaveStep2()}
            >
              <span className="btn" id="next">
                Etape suivante
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 3) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.incomplete}
            title="Données incomplètes !"
            type="warning"
            text="Vos données sont incomplètes. Merci de remplir l'ensemble des champs obligatoires marqués d'une étoile."
            onConfirm={e => {
              this.setState({ incomplete: false });
            }}
            confirmButtonText="Retour au formulaire"
            confirmButtonColor="#00437e"
          />
          <SweetAlert
            show={this.state.saveSuccess}
            title="Données enregistrées !"
            type="success"
            text="Vos données ont bien été enregistrées. Vous pouvez revenir consulter et modifier vos informations à tout moment d'ici à la fin de l'enquête."
            onConfirm={e => {
              this.setState({ saveSuccess: false });
              this.setState({ step: 4 });
              window.scrollTo(0, 0);
            }}
            confirmButtonText="Voir le récapitulatif"
            confirmButtonColor="#00437e"
            cancelButtonText="Modifier mes données"
            cancelButtonColor="#00437e"
          />
          <div className="block" id="formation">
            <SiseEtapeTitre title="Parcours académique" step={3} />
            <div className="form-group">
              <label
                style={
                  this.state.validationStep3.situationAnneePrecedente != null &&
                    !this.state.validationStep3.situationAnneePrecedente
                    ? { color: "red" }
                    : {}
                }
              >
                Situation année précédente *
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="situationAnneePrecedente"
                ref={this.inputSituationAnneePrecedenteFocus}
                value={
                  this.state.data != null &&
                    this.state.data.situationAnneePrecedente != null
                    ? this.state.data.situationAnneePrecedente
                    : ""
                }
                style={
                  this.state.validationStep3.situationAnneePrecedente != null &&
                    !this.state.validationStep3.situationAnneePrecedente
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listesituations
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label
                style={
                  this.state.validationStep3.dernierDiplome != null &&
                    !this.state.validationStep3.dernierDiplome
                    ? { color: "red" }
                    : {}
                }
              >
                Type du dernier diplôme obtenu *
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="dernierDiplome"
                ref={this.inputDernierDiplomeFocus}
                value={
                  this.state.data != null &&
                    this.state.data.dernierDiplome != null
                    ? this.state.data.dernierDiplome
                    : ""
                }
                style={
                  this.state.validationStep3.dernierDiplome != null &&
                    !this.state.validationStep3.dernierDiplome
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listediplomes
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>Type de classe préparatoire</label>
              <select
                className="selectpicker"
                data-width="auto"
                id="typeClassePreparatoire"
                value={
                  this.state.data != null &&
                    this.state.data.typeClassePreparatoire != null
                    ? this.state.data.typeClassePreparatoire
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listeprepas
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label
                style={
                  this.state.validationStep3.anneeObtentionBac != null &&
                    !this.state.validationStep3.anneeObtentionBac
                    ? { color: "red" }
                    : {}
                }
              >
                Année d'obtention du baccalauréat *
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="anneeObtentionBac"
                ref={this.inputAnneeObtentionBacFocus}
                value={
                  this.state.data != null &&
                    this.state.data.anneeObtentionBac != null
                    ? this.state.data.anneeObtentionBac
                    : ""
                }
                style={
                  this.state.validationStep3.anneeObtentionBac != null &&
                    !this.state.validationStep3.anneeObtentionBac
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listeannees.map((o, key) => (
                  <option key={key} value={o.code}>
                    {o.intitule}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label
                style={
                  this.state.validationStep3.typeBac != null &&
                    !this.state.validationStep3.typeBac
                    ? { color: "red" }
                    : {}
                }
              >
                Type de baccalauréat ou équivalence *
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="typeBac"
                ref={this.inputTypeBacFocus}
                value={
                  this.state.data != null && this.state.data.typeBac != null
                    ? this.state.data.typeBac
                    : ""
                }
                style={
                  this.state.validationStep3.typeBac != null &&
                    !this.state.validationStep3.typeBac
                    ? { borderColor: "red" }
                    : {}
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listebacs
                  .sort(function (a, b) {
                    //both values are transformed for case insensitive comparison
                    var nameA = a.intitule.toUpperCase();
                    var nameB = b.intitule.toUpperCase();
                    if (nameA === nameB)
                      return 0;
                    return nameA < nameB ? -1 : 1;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
              {this.state.displayBacSpecialites 
                && (<div>
                  <div className="form-group mt-5">
                  <label
                    style={
                      this.state.validationStep3.bac_spe1 != null &&
                        !this.state.validationStep3.bac_spe1
                        ? { color: "red" }
                        : {}
                    }>
                    Specialité n°1
              </label>
                  <select
                    className="selectpicker"
                    data-width="auto"
                    id="bac_spe1"
                    name="bac_spe1"
                    ref={this.inputTypeBacFocus}
                    value={this.state.data?.bac_spe1 || ""}
                    onMouseDown={this.handleSelectChange}
                    onChange={this.handleSelectChange}>
                    <option>Selectionner</option>
                    {listeSpecialites
                    // .filter(spe => 
                    //   this.state.data?.bac_spe2 
                    //   ? spe.short === this.state.data.bac_spe2
                    //   : true)
                      .sort(function (a, b) {
                        //both values are transformed for case insensitive comparison
                        var nameA = a.long.toUpperCase();
                        var nameB = b.long.toUpperCase();
                        if (nameA === nameB)
                          return 0;
                        return nameA < nameB ? -1 : 1;
                      })
                      .map(o => (
                        <option key={`spe1_${o.med}`} value={o.short}>
                          {o.long}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group mt-5">
                <label
                    style={
                      this.state.validationStep3.bac_spe2 != null &&
                        !this.state.validationStep3.bac_spe2
                        ? { color: "red" }
                        : {}
                    }>
                    Specialité n°2
              </label>
                  <select
                    className="selectpicker"
                    data-width="auto"
                    id="bac_spe2"
                    name="bac_spe2"
                    ref={this.inputTypeBacFocus}
                    value={this.state.data?.bac_spe2 || ""}
                    onMouseDown={this.handleSelectChange}
                    onChange={this.handleSelectChange}>
                    <option>Selectionner</option>
                    {listeSpecialites
                      // .filter(spe => 
                      //   this.state.data?.bac_spe1 
                      //   ? spe.short === this.state.data.bac_spe1
                      //   : true)
                      .sort(function (a, b) {
                        var nameA = a.long.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.long.toUpperCase(); // ignore upper and lowercase
                        if (nameA === nameB)
                          return 0;
                        return nameA < nameB ? -1 : 1;
                      })
                      .map((o, key) => (
                        <option key={`spe2_${o.med}`} value={o.short}>
                          {o.long}
                        </option>
                      ))}
                  </select>
                  </div>
                  </div>)}
            </div>
            {this.state.displayDepartementObtentionBac ? (
              <div className="form-group mt-5">
                <label
                  style={
                    this.state.validationStep3.departementObtentionBac !=
                      null &&
                      !this.state.validationStep3.departementObtentionBac
                      ? { color: "red" }
                      : {}
                  }
                >
                  Département d'obtention *
                </label>
                <br />
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  className=""
                  checked={this.state.graduatedAbroad}
                  onChange={this.handleGraduatedAbroad}
                />
                <label style={{ marginLeft: "10px" }}>
                  J'ai obtenu mon baccalauréat à l'étranger
                </label>
                {this.state.graduatedAbroad === null ||
                  !this.state.graduatedAbroad ? (
                    <select
                      className="selectpicker"
                      data-width="auto"
                      id="departementObtentionBac"
                      ref={this.inputDepartementObtentionBac}
                      value={
                        this.state.data != null &&
                          this.state.data.departementObtentionBac != null
                          ? this.state.data.departementObtentionBac
                          : ""
                      }
                      style={
                        this.state.validationStep3.departementObtentionBac !=
                          null &&
                          !this.state.validationStep3.departementObtentionBac
                          ? { borderColor: "red" }
                          : {}
                      }
                      onMouseDown={this.handleSelectChange}
                      onChange={this.handleSelectChange}
                    >
                      <option>Selectionner</option>
                      {listedepartements
                        .filter(o => o.code !== "990" && o.code !== "999" && o.code !== "99")
                        .sort(function (a, b) {
                          var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                          var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }

                          // names must be equal
                          return 0;
                        })
                        .map((o, key) => (
                          <option key={key} value={o.code}>
                            {o.intitule}
                          </option>
                        ))}
                    </select>
                  ) : (
                    ""
                  )}
              </div>
            ) : (
                ""
              )}
            <button
              className="col-sm-6 btn-group"
              onClick={() => {
                this.setState({ step: 2 });
                window.scrollTo(0, 0);
              }}
            >
              <span className="btn btn--ghost" id="next">
                Retour à l'étape précédente
              </span>
            </button>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.handleSaveStep3()}
            >
              <span className="btn" id="next">
                Enregistrer mes données
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 4) {
      return (
        <div className="mycampus-qcm">
          <div className="block" id="recap">
            <SiseRecapitulatif data={this.state.data} />
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.setState({ step: 1 })}
            >
              <span className="btn btn--ghost" id="next">
                Modifier mes informations
              </span>
            </button>
            <Link className="col-sm-6 btn-group" to="/">
              <span className="btn" id="next">
                Retourner à l'écran d'accueil
              </span>
            </Link>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
export default EnqueteSise;
