import React from "react";

class Alert extends React.Component {
    render() {
        return (
            <div className="alert alert-secondary" role="alert">

                <div
                    className="alertText"
                    dangerouslySetInnerHTML={{ __html: this.props.text }}
                    style={!this.props.defilement ? { animation: "null", paddingLeft: "0px" } : null}
                />

            </div>
        );
    }
}

export default Alert;
