import React from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";

class BackToHomeButton extends React.Component {
  render() {
    return (
      <Link to="/">
        <IoIosArrowRoundBack
          style={{ color: "#e11410", marginRight: "10px" }}
        />
      </Link>
    );
  }
}

export default BackToHomeButton;
