import React from "react";
import UserPicture from "../components/common/UserPicture";
import LightInsideLinkButton from "../components/common/LightInsideLinkButton";
import DarkOutsideLinkButton from "../components/common/DarkOutsideLinkButton";
import AsideTitle from "./AsideTitle";
import AsideSubTitle from "./AsideSubTitle";

import { graphClient } from "../authProvider";

class Aside extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photo: null,
      mails: null,
      me: this.props.me,
      openPhotoModal: false
    };
  }

  async componentDidMount() {
    await graphClient.api("/me/photo/$value").version("beta").get().then(photo => {
      if (!photo)
        return;
      photo.arrayBuffer().then(buffer => {
        var base64Flag = "data:image/jpeg;base64,";
        var binary = "";
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach(b => (binary += String.fromCharCode(b)));
        var imageStr = window.btoa(binary);
        photo = base64Flag + imageStr;
        this.setState({ photo });
        });
    }).catch(ex => {
      console.error("Erreur lors de la récupération de la photo de profil", ex);
    });

    await graphClient.api("/me/mailFolders/Inbox/messages?$filter=isRead%20ne%20true&$count=true").version("beta").get().then(nbMails => {
      if (nbMails) {
        this.setState({ mails: nbMails["@odata.count"] })
      }
    }).catch(ex => {
      console.error("Erreur lors de la récupération des mails", ex);
    });
  }

  render() {
    return (
      <div className="card card--pigier mb-5">
        <UserPicture photo={this.state.photo} />
        <div className="card-body">
          <AsideTitle
            text={this.props.me != null ? this.props.me.givenName : ""}
          />
          {this.props.me != null &&
            this.props.me
              .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_CompanyName !=
            null &&
            this.props.me
              .extension_0fb3318848f64e27bb07a8a91b147048_extensionAttribute2 !=
            null ? (
              <AsideSubTitle
                text={
                  this.props.me
                    .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_CompanyName +
                  " - " +
                  this.props.me
                    .extension_0fb3318848f64e27bb07a8a91b147048_extensionAttribute2
                }
              />
            ) : (
              ""
            )}
          <AsideSubTitle
            text={
              this.props.me != null && this.props.me.jobTitle != null
                ? this.props.me.jobTitle
                : ""
            }
          />
          <div className="d-flex justify-content-between mt-5" role="group">
            <DarkOutsideLinkButton
              link="https://outlook.office.com/owa"
              text="Mes messages"
              badge={this.state.mails != null ? this.state.mails : null}
            />
            <LightInsideLinkButton route="/profil" text="Mon profil" />
          </div>
        </div>
      </div>
    );
  }
}
export default Aside;
