export const formations = [
  {
    code: "BTS1",
    sise: false,
    scolege: true
  },
  {
    code: "BTS2",
    sise: false,
    scolege: true
  },
  {
    code: "BA1",
    sise: true,
    scolege: false
  },
  {
    code: "BA2",
    sise: true,
    scolege: false
  },
  {
    code: "BA3",
    sise: true,
    scolege: false
  },
  {
    code: "M1",
    sise: true,
    scolege: false
  },
  {
    code: "M2",
    sise: true,
    scolege: false
  },
  {
    code: "PB1",
    sise: false,
    scolege: true
  },
  {
    code: "PB2",
    sise: false,
    scolege: true
  },
  {
    code: "PB3",
    sise: false,
    scolege: true
    },
    {
        code: "DCTS1",
        sise: true,
        scolege: false
    },
    {
        code: "DCTS2",
        sise: true,
        scolege: false
    }
];
