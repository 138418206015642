import React from "react";
import { Link } from "react-router-dom";
import APIs from "../config/apis";

import { getAccessToken } from '../authProvider';

function ImageAside(props) {
  if (props.src.length > 0)
    return <img className="card-img mr-4" src={props.src} alt={props.alt} />;
  else return <div className="card-imgAbsente mr-4" />;
}

class NewsAside extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: null
    };
  }

  async componentDidMount() {
    var backendAccessToken = await getAccessToken();

      fetch(APIs.find(o => o.app === "backEnd").url + "news/5", {
        method: "GET",
        headers: {
          Authorization: `Bearer ` + backendAccessToken
        }
      }).then((response) =>{
        if(response.status===401)
          {
            throw new Error(response.status)
          }
          return response
        })
        .then(res => {
          if (res.ok) {
            return res.json();
          }
        })
        .then(res => {
          this.setState({ news: res });
        }).catch(error => {
          window.location.reload();
      });
    
  }

  handleClickNews = id => {
    window.scrollTo(0, id);
  };

  render() {
    if (this.state.news != null && this.state.news.length > 0) {
      return (
        <div className="card card--pigier mb-5">
          <div className="card-body">
            <h3 className="h3">Actualités</h3>
            <div className="accordion" id="accordion-questionM">
              {this.state.news.map((n, key) => (
                <div className="card border-bottom" key={key}>
                  <div
                    className="card-header mx-minus"
                    id="heading-questionM-1"
                    style={{ padding: 3 }}
                  >
                    <Link
                      onClick={() => this.handleClickNews(n.id)}
                      to={"/news/" + n.id}
                      className="btn-accordion-aside d-flex justify-content-between align-items-center collapsed"
                      type="button"
                      //data-toggle="collapse"
                      data-target={"#collapse-question-" + n.id}
                      aria-expanded="false"
                      aria-controls={"#collapse-question-" + n.id}
                      style={{ width: "100%" }}
                    >
                      <li className="media">
                        <ImageAside src={n.image} alt="" />
                        <div className="media-body">
                          <p className="media-body__p">{n.subject}</p>
                        </div>
                      </li>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-right pt-2">
              <Link to="/news">
                <span
                  className="btn btn-pigier btn-primary position-relative"
                  style={{ padding: 8 }}
                >
                  Voir plus &gt;
                </span>
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default NewsAside;
