import React from "react";
import MyApp from "./MyApp";
class MyFavoriteApps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topApps: this.props.topApps
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.topApps !== this.props.topApps) {
      this.setState({ topApps: this.props.topApps })
    }
  }

  render() {
    return this.state.topApps && this.state.topApps.length > 0 ? (
      <section className="c-section-card">
        <h2 className="h2">Mes outils fréquemment utilisés</h2>
        <p className="card-text mb-5">
          Retrouvez ici les outils pédagogiques que vous avez le plus utilisés
          depuis votre première connexion.
        </p>
        <div className="row">
          {this.state.topApps.map((app, key) => (
              <MyApp
                key={key}
                application={app}
                account={this.props.account}
              />
            ))}
        </div>
      </section>
    ) : (
      ""
    );
  }
}
export default MyFavoriteApps;
