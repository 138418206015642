import React from "react";
import SweetAlert from "sweetalert-react";
import { msalClient } from '../authProvider';

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mail: "prenom.nom@domaine.org"
        };
    }

    render() {
        return (<div className="holder">
            <SweetAlert
                show
                title="Accès refusé"
                type="error"
                cancelButtonColor="#00437e"
                text={"Le compte actuellement connecté n’est pas autorisé à accéder au Front Office MyCampus.\nCliquer sur <Changer de compte> puis réessayer à nouveau."}
                onConfirm={() => {
                    msalClient.logout();
                }}
                confirmButtonText="Changer de compte"
                confirmButtonColor="#000000"
            />
        </div>)
    }
}

export default Error;