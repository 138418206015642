import React from "react";
import MyApps from "../components/MyApps";
import MyFavoriteApps from "../components/MyFavoriteApps";
import CTASise from "../components/CTASise";
import CTAScolege from "../components/CTAScolege";
import CTAAlert from "../components/CTAAlert";

import APIs from "../config/apis";
import { allapps } from "../config/apps";
import { graphClient, getAccessToken } from "../authProvider";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: null,
      accessTokenBackEnd: null,
      topApps: null
    };
  }

  async componentDidMount() {
    graphClient.api("/me/appRoleAssignments").version("beta").get((error, response) => {
      var applications = response.value;

      if (applications) {
        var promises = [];
        applications.forEach(app => {
          if (app && app.resourceId) {
            promises.push(graphClient.api("/servicePrincipals/" + app.resourceId).version("beta").get((error, application) => {
              if (application && Object.keys(app) && Object.keys(app).length) {
                Object.keys(application).forEach(key => app[key] = application[key])
              }
            }));
          }
        })
        Promise.all(promises).then(() => {
          applications = applications.filter(c => c !== undefined && c !== null && allapps.find(a => a.id === c.appId));
          getAccessToken().then(backendAccessToken => {
            fetch(APIs.find(o => o.app === "backEnd").url + "apptools", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${backendAccessToken}`,
              },
              body: JSON.stringify({ apps: applications.map(app => app.resourceId) })
            }).then(res => {
              if (res.ok) {
                return res.json();
              }
            }).then(applicationsWithTools => {
              if (applicationsWithTools) {
                applications = applications.map(a => {
                  a.toolId = applicationsWithTools.find(app => app.appId === a.resourceId) ? applicationsWithTools.find(app => app.appId === a.resourceId).id : null;
                  return a;
                });
                applicationsWithTools = applicationsWithTools.map(tool => {
                  return { id: tool.id, label: tool.label }
                })
                var tools = [];
                applicationsWithTools.forEach(tool => {
                  if (!tools.find(t => t.id === tool.id)) {
                    tools.push(tool)
                  }
                })
                this.setState({ applications, tools })
              } else {
                this.setState({ applications });
              }

              fetch(APIs.find(o => o.app === "backEnd").url + "aadapps", {
                method: "GET",
                headers: {
                  Authorization: `Bearer ` + backendAccessToken
                }
              }).then((response) => {
                if (response.status === 401) {
                  throw new Error(response.status)
                }
                return response
              })
                .then(res => {
                  if (res.ok) {
                    return res.json();
                  }
                })
                .then(res => {
                  var favAppFiltered = Object.assign([], applications);
                  favAppFiltered = favAppFiltered.filter(app => res.find(a => a.aadAppId === app.resourceId));
                  this.setState({ topApps: favAppFiltered });
                })
            })
          });
        });
      };
    });
  }

  render() {
    return (
      <div>
        <CTAAlert />
        { this.props.formation != null &&
          this.props.formation.sise != null &&
          this.props.formation.sise ? (
          <CTASise />
        ) : (
          ""
        )}
        { this.props.formation != null &&
          this.props.formation.scolege != null &&
          this.props.formation.scolege ? (
          <CTAScolege />
        ) : (
          ""
        )}
        <div className="card">
          <div className="card-body">
            {/* <MyFavoriteApps topApps={this.state.topApps} /> */}
            <MyApps applications={this.state.applications} tools={this.state.tools} />
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
