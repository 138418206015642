import React from "react";

export default function () {
        return (
                <div className="holder">
                        <div className="preloader">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                        </div>
                </div>
        );
}