import React from "react";

class DarkOutsideLinkButton extends React.Component {
  render() {
    return (
      <a
        className="btn btn-pigier btn-primary position-relative"
        href={this.props.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {this.props.text}
        {this.props.badge != null ? (
          <span className="badge badge-danger position-absolute">
            {this.props.badge}
          </span>
        ) : (
          ""
        )}
      </a>
    );
  }
}

export default DarkOutsideLinkButton;
