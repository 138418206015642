import React from "react";
import CardTitle from "../layout/CardTitle";
import ListItem from "../components/ListItem";

class Profil extends React.Component {
  render() {
    return (
      <div className="card border-md-1">
        <div className="card-body">
          <section className="c-section-card">
            <CardTitle
              title="Informations personnelles"
              subtitle="Certaines informations peuvent être visibles par d’autres utilisateurs."
            />
            <ul className="c-profile-list">
              <ListItem
                title="Civilité"
                value={
                  this.props.user != null &&
                  this.props.user
                    .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_TitleName !=
                    null
                    ? this.props.user
                        .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_TitleName
                    : ""
                }
              />
              <ListItem
                title="Prénom"
                value={
                  this.props.user != null && this.props.user.givenName != null
                    ? this.props.user.givenName
                    : ""
                }
              />
              <ListItem
                title="Nom"
                value={
                  this.props.user != null && this.props.user.surname != null
                    ? this.props.user.surname
                    : ""
                }
              />
              <ListItem
                title="Statut"
                value={
                  this.props.user != null && this.props.user.jobTitle != null
                    ? this.props.user.jobTitle
                    : ""
                }
              />
              <ListItem
                title="Marque"
                value={
                  this.props.user != null &&
                  this.props.user
                    .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_CompanyName !=
                    null
                    ? this.props.user
                        .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_CompanyName
                    : ""
                }
              />
              <ListItem
                title="Etablissement"
                value={
                  this.props.user != null &&
                  this.props.user
                    .extension_0fb3318848f64e27bb07a8a91b147048_extensionAttribute2 !=
                    null
                    ? this.props.user
                        .extension_0fb3318848f64e27bb07a8a91b147048_extensionAttribute2
                    : ""
                }
              />
              <ListItem
                title="Formation"
                value={
                  this.props.user != null &&
                  this.props.user
                    .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel !=
                    null
                    ? this.props.user
                        .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel
                    : ""
                }
              />
              <ListItem
                title="Identifiant"
                value={
                  this.props.user != null &&
                  this.props.user.onPremisesSamAccountName != null
                    ? this.props.user.onPremisesSamAccountName
                    : ""
                }
              />
              <ListItem
                title="Adresse e-mail"
                value={
                  this.props.user != null &&
                  this.props.user.userPrincipalName != null
                    ? this.props.user.userPrincipalName
                    : ""
                }
              />
              <li className="c-profile-list__item">
                <div className="row">
                  <div className="col-12 col-md-3">
                    <strong> Mot de passe</strong>
                  </div>
                  <div className="col-12 col-md-6">
                    <p> *********</p>
                  </div>
                  <div className="col-12 col-md-3 d-none d-md-flex justify-content-end">
                    <button
                      className="h-link-simple float-right"
                      style={{ fontSize: "13px" }}
                      onClick={() => {
                        window.open(
                          "https://account.activedirectory.windowsazure.com/ChangePassword.aspx?BrandContextID=O365&ruO365=",
                          "newwindow",
                          "width=970,height=650"
                        );
                        return false;
                      }}
                    >
                      modifier mon mot de passe
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    );
  }
}
export default Profil;
