import React from "react";
import { Link } from "react-router-dom";

class Toast extends React.Component {
  render() {
    return (
      <div
        className="toast toast--pigier mb-5 fade show"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-header">
          <div>
            <p>
              <b>{this.props.title}</b>
              {this.props.text}
              <Link className="ml-2" to={this.props.route}>
                {this.props.buttonText}
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Toast;
