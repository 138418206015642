import React from "react";

class AsideTitle extends React.Component {
  render() {
    return (
      <div className="card-body__title text-center mb-1">
        Bonjour {this.props.text}
      </div>
    );
  }
}

export default AsideTitle;
