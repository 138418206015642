import React from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaInfoCircle } from "react-icons/fa";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import {
  listepays,
  listebacs,
  listedepartements,
  listecommunes,
  listeregimesscolaires,
  listesecteurs,
  listesexes
} from "../config/referentielSiseScolege";
import ReactTooltip from "react-tooltip";
import { formations } from "../config/formations";
import APIs from "../config/apis";
import { getAccessToken } from '../authProvider';

class EnqueteScolege extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: null,
      data: null,
      originalData: null,
      saveSuccess: false,
      saveError: false,
      authorized: false,
      bornInFrance: true,
      validationStep2: { option1: null, regimeScolaire: null },
      validationStep3: {
        formationAnneePrecedente: null,
        secteurEtablissementAnneePrecedente: null,
        departementEtablissementAnneePrecedente: null
      }
    };
    this.handleSaveStep1 = this.handleSaveStep1.bind(this);
    this.handleSaveStep2 = this.handleSaveStep2.bind(this);
    this.handleSaveStep3 = this.handleSaveStep3.bind(this);
    this.searchCommuneNaissance = this.searchCommuneNaissance.bind(this);
    this.searchCommuneResidence = this.searchCommuneResidence.bind(this);
    this.loadUser = this.loadUser.bind(this);
  }

  async loadUser() {
    var backendAccessToken = await getAccessToken();

    fetch(APIs.find(o => o.app === "backEnd").url + "scolege", {
      method: "GET",
      headers: {
        Authorization: `Bearer ` + backendAccessToken
      }
    })
      .then(res => {
        if (res && res.ok) {
          return res.json();
        }
      })
      .then(res => {
        if (res.communeNaissance) {
          this.setState({ bornInFrance: true });

          if (
            listecommunes.find(
              o =>
                o.intitule.toLowerCase() ===
                res.communeNaissance.toLowerCase()
            ) != null
          ) {
            res.communeNaissanceCode = listecommunes.find(
              o =>
                o.intitule.toLowerCase() ===
                res.communeNaissance.toLowerCase()
            ).code;

            var listecommunesFiltered = listecommunes.filter(
              o => o.code === res.communeNaissanceCode
            );
            this.setState({ listecommunesFiltered });
          }
        } else {
          this.setState({ bornInFrance: false });
        }
        if (
          res.departementObtentionBac != null &&
          res.departementObtentionBac.startsWith("099")
        ) {
          this.setState({ graduatedAbroad: true });
        }
        if (
          res.typeBac != null &&
          listebacs.find(
            o => o.code === res.typeBac && o.type === "Equivalence"
          )
        ) {
          this.setState({ displayDepartementObtentionBac: false });
        } else {
          this.setState({ displayDepartementObtentionBac: true });
        }
        this.setState({ data: res, originalData: res });
      });
  }

  async componentDidMount() {
    if (
      this.props.user != null &&
      this.props.user.jobTitle === "Etudiant" &&
      this.props.user
        .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel !=
      null
    ) {
      var formation = formations.find(
        o =>
          o.code ===
          this.props.user
            .extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel
      );
      if (formation != null && formation.scolege) {
        this.setState({ authorized: true });
      } else {
        this.setState({ authorized: false });
      }
    } else {
      this.setState({ authorized: false });
    }

  }

  searchCommuneNaissance = event => {
    var data = { ...this.state.data };
    data.inputCommuneNaissance = event.target.value;
    this.setState({ data });
    if (
      data.inputCommuneNaissance != null &&
      data.inputCommuneNaissance.length > 1
    ) {
      var listecommunesFiltered = listecommunes
        .filter(
          o =>
            o.intitule
              .toLowerCase()
              .replace("é", "e")
              .replace("è", "e")
              .replace("ë", "e")
              .replace("ê", "e")
              .replace("à", "a")
              .replace("ä", "a")
              .replace("ï", "i")
              .replace("ö", "o")
              .replace("ü", "u")
              .replace("ù", "u")
              .startsWith(
                data.inputCommuneNaissance
                  .toLowerCase()
                  .replace("é", "e")
                  .replace("è", "e")
                  .replace("ë", "e")
                  .replace("ê", "e")
                  .replace("à", "a")
                  .replace("ä", "a")
                  .replace("ï", "i")
                  .replace("ö", "o")
                  .replace("ü", "u")
                  .replace("ù", "u")
              ) || o.cp.includes(data.inputCommuneNaissance)
        ).sort(function (a, b) {
          var nameA = a.intitule.toUpperCase();
          var nameB = b.intitule.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
    }
    this.setState({ listecommunesFiltered });
    if (listecommunesFiltered != null && listecommunesFiltered.length > 0) {
      data.communeNaissance = listecommunesFiltered[0].intitule;
      data.communeNaissanceCode = listecommunesFiltered[0].code;
    } else {
      data.communeNaissance = null;
      data.communeNaissanceCode = null;
    }
    this.setState({ data });
  };

  searchCommuneResidence = event => {
    var data = { ...this.state.data };
    data.inputCommuneResidence = event.target.value;
    this.setState({ data });

    if (
      data.inputCommuneResidence != null &&
      data.inputCommuneResidence.length > 2 &&
      listecommunes.find(
        o =>
          o.intitule.toLowerCase() === data.inputCommuneResidence.toLowerCase()
      ) != null
    ) {
      data.communeResidence = listecommunes.find(
        o =>
          o.intitule.toLowerCase() === data.inputCommuneResidence.toLowerCase()
      ).intitule;
    } else if (
      data.inputCommuneResidence != null &&
      data.inputCommuneResidence.length > 2 &&
      listecommunes.find(o =>
        o.intitule
          .toLowerCase()
          .startsWith(data.inputCommuneResidence.toLowerCase())
      ) != null
    ) {
      data.communeResidence = listecommunes.find(o =>
        o.intitule
          .toLowerCase()
          .startsWith(data.inputCommuneResidence.toLowerCase())
      ).intitule;
    } else {
      data.communeResidence = null;
    }
    this.setState({ data });
  };

  async handleSaveStep1() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    if (data.numeroIne && data.numeroIne.length > 11) {
      this.setState({ errors: ["numeroIne"], incomplete: true });
    } else {
      fetch(APIs.find(o => o.app === "backEnd").url + "scolege", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ` + backendAccessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.ok) {
          this.loadUser();
          this.setState({ step: 2, errors: false, incomplete: false });
          window.scrollTo(0, 0);
        } else {
          throw new Error(res.status);
        }
      }).catch(error => {
        this.setState({ saveError: true, incomplete: false });
      });
    }
  }

  async handleSaveStep2() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    var invalidField = this.getInvalidField(this.state.validationStep2, data);
    if (!invalidField) {
      fetch(APIs.find(o => o.app === "backEnd").url + "scolege", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ` + backendAccessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.ok) {
          this.loadUser();
          this.setState({ step: 3, errors: false, incomplete: false });
          window.scrollTo(0, 0);
        } else {
          throw new Error(res.status);
        }
      }).catch(error => {
        this.setState({ saveError: true, incomplete: false });
      });
    } else {
      this.setState({ errors: Object.keys(invalidField).filter(key => invalidField[key] === true), incomplete: true });
    }
  }

  async handleSaveStep3() {
    var backendAccessToken = await getAccessToken();
    var data = this.state.data;
    var invalidField = this.getInvalidField(this.state.validationStep3, data);
    if (!invalidField) {
      fetch(APIs.find(o => o.app === "backEnd").url + "scolege", {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ` + backendAccessToken,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.ok) {
          this.loadUser();
          this.setState({ errors: false, saveSuccess: true, incomplete: false });
          window.scrollTo(0, 0);
        } else {
          throw new Error(res.status);
        }
      }).catch(error => {
        this.setState({ saveError: true, incomplete: false });
      });
    } else {
      this.setState({ errors: Object.keys(invalidField).filter(key => invalidField[key] === true), incomplete: true });
    }
  }

  getInvalidField(fieldToValidate, data) {
    var isValid = true;
    Object.keys(fieldToValidate).forEach(o => {
      if (!data[o] || data[o] === "") {
        fieldToValidate[o] = true;
        isValid = false;
      } else {
        fieldToValidate[o] = false;
      }
    });

    if (isValid) {
      return false;
    } else {
      return fieldToValidate;
    }
  }

  handleInputChange = event => {
    var data = { ...this.state.data };
    data[event.target.id] = event.target.value;
    this.setState({
      data
    });
  };

  handleSelectChange = event => {
    if (event.target.value !== "Selectionner") {
      var data = { ...this.state.data };
      if (event.target.id != null && event.target.id !== "") {
        data[event.target.id] = event.target.value;
      } else {
        data[event.target.parentElement.id] = event.target.value;
      }
      this.setState({
        data
      });
    }
  };

  handleBornInFrance = event => {
    var data = { ...this.state.data };
    var value = event.target.checked;
    data.inputCommuneNaissance = null;
    data.paysNaissance = null;
    data.communeNaissance = null;
    data.communeNaissanceCode = null;
    this.setState({ bornInFrance: value, data });
  };

  handleCommuneNaissanceChange = event => {
    var data = { ...this.state.data };
    if (event.target.value !== "Selectionner") {
      var commune = listecommunes.find(o => o.code === event.target.value);
      data.communeNaissance = commune.intitule;
      data.communeNaissanceCodePostal = commune.cp;
    } else {
      data.communeNaissance = null;
      data.communeNaissanceCode = null;
    }
    this.setState({
      data
    });
  };

  render() {
    if (!this.state.authorized) {
      return (
        <div
          className="toast toast--pigier mb-5 fade show"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <div>
              <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
              <Link className="ml-2" to="/">
                Revenir à l'accueil
              </Link>
            </div>
          </div>
        </div>
      );
    } else if (this.state.step === null) {
      var errorsText = "Des champs obligatoires ont été omis lors de votre saisie des informations :";
      if (this.state.errors && this.state.errors.length) {
        this.state.errors.forEach(err => {
          errorsText += err + " ,"
        })
      }
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.saveSuccess}
            title="Données enregistrées !"
            type="success"
            text="Vos données ont bien été enregistrées. Vous pouvez revenir consulter et modifier vos informations à tout moment d’ici à la fin de l’enquête."
            onConfirm={e => {
              this.setState({ saveSuccess: false });
              window.history.back();
            }}
            confirmButtonText="Retour à l'accueil"
            confirmButtonColor="#00437e"
          />
          <SweetAlert
            show={this.state.saveError}
            title="Oops !"
            type="error"
            text="Une erreur s'est produite. Si le problème persiste, merci de contacter votre assistant pédagogique."
            confirmButtonText="Revenir au formulaire"
            onConfirm={() => this.setState({ saveError: false })}
          />
          <SweetAlert
            show={this.state.errors}
            title="Oops !"
            type="error"
            text={errorsText}
            confirmButtonText="Revenir au formulaire"
            onConfirm={() => this.setState({ errors: false })}
          />
          <div className="block" id="intro">
            <h1>
              Bienvenue
              <span className="subtitle">Enquête SCOLEGE</span>
            </h1>
            <p className="content">
              Le ministère de l’Education Nationale lance chaque année une
              enquête de recensement auprès des étudiants de l’enseignement
              supérieur.
            </p>
            <Link to="/" className="col-sm-6 btn-group">
              <span className="btn btn--ghost" id="prev">
                Retourner vers l’écran d’accueil
              </span>
            </Link>
            <button
              className="col-sm-6 btn-group"
              onClick={() => {
                this.loadUser();
                this.setState({ step: 1 });
              }}
            >
              <span className="btn" id="next">
                Répondre
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 1) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.saveError}
            title="Oops !"
            type="error"
            text="Une erreur s'est produite. Si le problème persiste, merci de contacter votre assistant pédagogique."
            confirmButtonText="Revenir au formulaire"
            onConfirm={() => this.setState({ saveError: false })}
          />
          <SweetAlert
            show={this.state.incomplete}
            title="Données invalides !"
            type="warning"
            text="Merci de vérifier les consignes de remplissage des informations. Le champ INE doit obligatoirement faire 11 caractères numériques."
            onConfirm={e => {
              this.setState({ incomplete: false });
            }}
            confirmButtonText="Retour au formulaire"
            confirmButtonColor="#00437e"
          />
          <div className="block" id="step1">
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="steps with-title">
                    <ul>
                      <li data-step="1" className="step active" />
                      <li data-step="2" className="step" />
                      <li data-step="3" className="step" />
                    </ul>
                  </div>
                  <div
                    className="steps without-title mt-4"
                    style={{ width: "100%", left: 0, transform: "none" }}
                  >
                    <ul className="level1 active" data-step="1">
                      <li className="level2" />
                    </ul>
                    <ul className="level1" data-step="2">
                      <li className="level2" />
                    </ul>
                    <ul className="level1" data-step="3">
                      <li className="level2" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <span className="subtitle subtitle--secondary mb0">
              Enquête SCOLEGE
            </span>
            <h1>Civilité de l’élève</h1>
            <div className={this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "numeroIne") ? "form-group mt-5 error" : "form-group mt-5"}>
              <label>INE - RNIE</label>
              {this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "numeroIne") ? <span className="error-message">Le numéro INE doit obligatoirement faire 11 caractères.</span> : null}
              <input
                className="form-control"
                id="numeroIne"
                placeholder=""
                maxLength="11"
                value={this.state.data && this.state.data.numeroIne ? this.state.data.numeroIne : ""}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group">
              <label>Prénom 2</label>
              <input
                type="text"
                className="form-control"
                id="prenom2"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.prenom2 != null
                    ? this.state.data.prenom2
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>Prénom 3</label>
              <input
                type="text"
                className="form-control"
                id="prenom3"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.prenom3 != null
                    ? this.state.data.prenom3
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>Commune de naissance</label>
              <br />
              <input
                type="checkbox"
                id="scales"
                name="scales"
                className=""
                checked={this.state.bornInFrance}
                onChange={this.handleBornInFrance}
              />
              <label style={{ marginLeft: "10px" }}>
                Je suis né(e) en France
              </label>
              {this.state.bornInFrance ? (
                <>
                  <input
                    type="text"
                    className="form-control input_search"
                    id="inputCommuneNaissance "
                    aria-describedby="emailHelp"
                    ref={this.inputCommuneNaissanceFocus}
                    placeholder="Entrer les premières lettres ou le code postal de la commune"
                    value={
                      this.state.data != null &&
                        this.state.data.inputCommuneNaissance != null
                        ? this.state.data.inputCommuneNaissance
                        : ""
                    }
                    onChange={this.searchCommuneNaissance}
                  />
                  <select
                    className="selectpicker"
                    data-width="auto"
                    id="communeNaissance"
                    value={
                      this.state.data != null &&
                        this.state.data.communeNaissanceCode != null
                        ? this.state.data.communeNaissanceCode 
                        : ""
                    }
                    onChange={this.handleCommuneNaissanceChange}
                  >
                    <option>Selectionner</option>
                    {this.state.listecommunesFiltered != null &&
                      this.state.listecommunesFiltered.length > 0
                      ? this.state.listecommunesFiltered
                        .sort(function (a, b) {
                          var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                          var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }

                          // names must be equal
                          return 0;
                        })
                        .map((o, key) => (
                          <option key={key} value={o.code}>
                            {o.intitule.toUpperCase() + " (" + o.cp + ")"}
                          </option>
                        ))
                      : ""}
                  </select>
                </>
              ) : null}
            </div>
            <div className="form-group mt-5">
              <label>Nationalité</label>
              <select
                className="selectpicker"
                data-width="auto"
                id="nationalite"
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
                value={
                  this.state.data != null && this.state.data.nationalite != null
                    ? this.state.data.nationalite
                    : ""
                }
              >
                <option>Selectionner</option>
                {listepays
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>Commune de résidence</label>
              <input
                type="text"
                className="form-control input_search"
                id="inputCommuneResidence"
                aria-describedby="emailHelp"
                placeholder="Tapez ici les premières lettres de la commune"
                value={
                  this.state.data != null &&
                    this.state.data.inputCommuneResidence != null
                    ? this.state.data.inputCommuneResidence
                    : ""
                }
                onChange={this.searchCommuneResidence}
              />
              <input
                type="text"
                className="form-control"
                id="communeResidence"
                aria-describedby="emailHelp"
                readOnly
                value={
                  this.state.data != null &&
                    this.state.data.communeResidence != null
                    ? this.state.data.communeResidence
                    : this.state.data != null &&
                      this.state.data.inputCommuneResidence != null
                      ? "Commune non trouvée"
                      : ""
                }
              />
            </div>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.setState({ step: null })}
            >
              <span className="btn btn--ghost" id="next">
                Retour à l'étape précédente
              </span>
            </button>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.handleSaveStep1()}
            >
              <span className="btn" id="next">
                Etape suivante
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 2) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.saveError}
            title="Oops !"
            type="error"
            text="Une erreur s'est produite. Si le problème persiste, merci de contacter votre assistant pédagogique."
            confirmButtonText="Revenir au formulaire"
            onConfirm={() => this.setState({ saveError: false })}
          />
          <SweetAlert
            show={this.state.incomplete}
            title="Données incomplètes !"
            type="warning"
            text="Vos données sont incomplètes. Merci de remplir l'ensemble des champs obligatoires marqués d'une étoile."
            onConfirm={e => {
              this.setState({ incomplete: false });
            }}
            confirmButtonText="Retour au formulaire"
            confirmButtonColor="#00437e"
          />
          <div className="block" id="infos">
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="steps with-title">
                    <ul>
                      <li data-step="1" className="step active" />
                      <li data-step="2" className="step active" />
                      <li data-step="3" className="step" />
                    </ul>
                  </div>
                  <div
                    className="steps without-title mt-4"
                    style={{ width: "100%", left: 0, transform: "none" }}
                  >
                    <ul className="level1 active" data-step="1">
                      <li className="level2" />
                    </ul>
                    <ul className="level1 active" data-step="2">
                      <li className="level2" />
                    </ul>
                    <ul className="level1" data-step="3">
                      <li className="level2" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <span className="subtitle subtitle--secondary mb0">
              Enquête SCOLEGE
            </span>
            <h1>Scolarité de l’année en cours</h1>
            <div className={this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "option1") ? "form-group mt-5 error" : "form-group mt-5"}>
              <label>
                Option 1 *
                <FaInfoCircle
                  data-tip
                  data-for="option1"
                  style={{ marginLeft: "12px", marginBottom: "3px" }}
                />
              </label>
              {this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "option1") ? <span className="error-message">Ce champ est obligatoire</span> : null}
              <input
                type="text"
                className="form-control"
                id="option1"
                required
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option1 != null
                    ? this.state.data.option1
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Option 2
                <FaInfoCircle
                  data-tip
                  data-for="option2"
                  style={{ marginLeft: "12px", marginBottom: "3px" }}
                />
              </label>
              <input
                type="text"
                className="form-control"
                id="option2"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option2 != null
                    ? this.state.data.option2
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Option 3
                <FaInfoCircle
                  data-tip
                  data-for="option3"
                  style={{ marginLeft: "12px", marginBottom: "3px" }}
                />
              </label>
              <input
                type="text"
                className="form-control"
                id="option3"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option3 != null
                    ? this.state.data.option3
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Option 4
                <FaInfoCircle
                  data-tip
                  data-for="option46"
                  style={{ marginLeft: "12px", marginBottom: "3px" }}
                />
              </label>
              <input
                type="text"
                className="form-control"
                id="option4"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option4 != null
                    ? this.state.data.option4
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Option 5
                <FaInfoCircle
                  data-tip
                  data-for="option46"
                  style={{ marginLeft: "12px", marginBottom: "3px" }}
                />
              </label>
              <input
                type="text"
                className="form-control"
                id="option5"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option5 != null
                    ? this.state.data.option5
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Option 6
                <FaInfoCircle
                  data-tip
                  data-for="option46"
                  style={{ marginLeft: "12px" }}
                />
              </label>
              <input
                type="text"
                className="form-control"
                id="option6"
                aria-describedby="emailHelp"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option6 != null
                    ? this.state.data.option6
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className="form-group mt-5">
              <label>
                Option 7
                <FaInfoCircle
                  data-tip
                  data-for="option46"
                  style={{ marginLeft: "12px" }}
                />
              </label>
              <input
                type="text"
                className="form-control"
                id="option7"
                placeholder=""
                value={
                  this.state.data != null && this.state.data.option7 != null
                    ? this.state.data.option7
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className={this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "regimeScolaire") ? "form-group error" : "form-group"}>
              <label>Régime scolaire *</label>
              {this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "regimeScolaire") ? <span className="error-message">Ce champ est obligatoire</span> : null}
              <select
                className="selectpicker"
                data-width="auto"
                id="regimeScolaire"
                value={
                  this.state.data != null &&
                    this.state.data.regimeScolaire != null
                    ? this.state.data.regimeScolaire
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listeregimesscolaires.map((o, key) => (
                  <option key={key} value={o.code}>
                    {o.intitule}
                  </option>
                ))}
              </select>
            </div>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.setState({ step: 1 })}
            >
              <span className="btn btn--ghost" id="next">
                Retour à l'étape précédente
              </span>
            </button>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.handleSaveStep2()}
            >
              <span className="btn" id="next">
                Etape suivante
              </span>
            </button>
          </div>
          <ReactTooltip id="option1" type="info">
            <span>Merci d'entrer dans ce champ votre LV1</span>
          </ReactTooltip>
          <ReactTooltip id="option2" type="info">
            <span>
              Merci d'entrer dans ce champ votre LV1 (classes bi langues) sinon
              LV2 sinon toute autre matière (LV3, arts plastiques...)
            </span>
          </ReactTooltip>
          <ReactTooltip id="option3" type="info">
            <span>
              Cas de la 2nde GT : premier enseignement d'exploration (un
              enseignement d'économie)
              <br />
              Cas de la terminale Littéraire : LV étrangère prise pour la
              littérature étrangère
              <br />
              Cas de la terminale Scientifique et terminale ES : enseignement de
              spécialité
              <br />
              Tous les autres cas : LV2 sinon toute autre matière
            </span>
          </ReactTooltip>
          <ReactTooltip id="option46" type="info">
            <span>
              Le deuxième enseignement d'exploration si l'élève est en seconde
              GT ou l'enseignement de spécialité pour la terminale L et toutes
              autres manières.
            </span>
          </ReactTooltip>
        </div>
      );
    } else if (this.state.step === 3) {
      return (
        <div className="mycampus-qcm">
          <SweetAlert
            show={this.state.saveSuccess}
            title="Données enregistrées !"
            type="success"
            text="Vos données ont bien été enregistrées. Vous pouvez revenir consulter et modifier vos informations à tout moment d'ici à la fin de l'enquête."
            onConfirm={e => {
              this.setState({ saveSuccess: false });
              this.setState({ step: 4 });
              window.scrollTo(0, 0);
            }}
            confirmButtonText="Voir le récapitulatif"
            confirmButtonColor="#00437e"
            cancelButtonText="Modifier mes données"
            cancelButtonColor="#00437e"
          />
          <SweetAlert
            show={this.state.saveError}
            title="Oops !"
            type="error"
            text="Une erreur s'est produite. Si le problème persiste, merci de contacter votre assistant pédagogique."
            confirmButtonText="Revenir au formulaire"
            onConfirm={() => this.setState({ saveError: false })}
          />
          <SweetAlert
            show={this.state.incomplete}
            title="Données incomplètes !"
            type="warning"
            text="Vos données sont incomplètes. Merci de remplir l'ensemble des champs obligatoires marqués d'une étoile."
            onConfirm={e => {
              this.setState({ incomplete: false });
            }}
            confirmButtonText="Retour au formulaire"
            confirmButtonColor="#00437e"
          />
          <div className="block" id="formation">
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="steps with-title">
                    <ul>
                      <li data-step="1" className="step active" />
                      <li data-step="2" className="step active" />
                      <li data-step="3" className="step active" />
                    </ul>
                  </div>
                  <div
                    className="steps without-title mt-4"
                    style={{ width: "100%", left: 0, transform: "none" }}
                  >
                    <ul className="level1 active" data-step="1">
                      <li className="level2" />
                    </ul>
                    <ul className="level1 active" data-step="2">
                      <li className="level2" />
                    </ul>
                    <ul className="level1 active" data-step="3">
                      <li className="level2" />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <span className="subtitle subtitle--secondary mb0">
              Enquête SCOLEGE
            </span>
            <h1>Scolarité de l’année précédente</h1>
            <div className={this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "formationAnneePrecedente") ? "form-group mt-5 error" : "form-group mt-5"}>
              <label>Formation année précédente *</label>
              {this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "formationAnneePrecedente") ? <span className="error-message">Ce champ est obligatoire</span> : null}
              <input
                type="text"
                className="form-control"
                id="formationAnneePrecedente"
                placeholder=""
                value={
                  this.state.data != null &&
                    this.state.data.formationAnneePrecedente != null
                    ? this.state.data.formationAnneePrecedente
                    : ""
                }
                onChange={this.handleInputChange}
              />
            </div>
            <div className={this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "secteurEtablissementAnneePrecedente") ? "form-group mt-5 error" : "form-group mt-5"}>
              <label>
                Secteur établissement année précédente *
              </label>
              {this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "secteurEtablissementAnneePrecedente") ? <span className="error-message">Ce champ est obligatoire</span> : null}
              <select
                className="selectpicker"
                data-width="auto"
                id="secteurEtablissementAnneePrecedente"
                value={
                  this.state.data != null &&
                    this.state.data.secteurEtablissementAnneePrecedente != null
                    ? this.state.data.secteurEtablissementAnneePrecedente
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listesecteurs
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className={this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "departementEtablissementAnneePrecedente") ? "form-group mt-5 error" : "form-group mt-5"}>
              <label>
                Département établissement année précédente *
              </label>
              {this.state.errors && this.state.errors.length && this.state.errors.find(x => x === "departementEtablissementAnneePrecedente") ? <span className="error-message">Ce champ est obligatoire</span> : null}
              <select
                className="selectpicker"
                data-width="auto"
                id="departementEtablissementAnneePrecedente"
                value={
                  this.state.data != null &&
                    this.state.data.departementEtablissementAnneePrecedente !=
                    null
                    ? this.state.data.departementEtablissementAnneePrecedente
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listedepartements
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group mt-5">
              <label>
                Type de baccalauréat ou équivalence
              </label>
              <select
                className="selectpicker"
                data-width="auto"
                id="typeBac"
                value={
                  this.state.data != null && this.state.data.typeBac != null
                    ? this.state.data.typeBac
                    : ""
                }
                onMouseDown={this.handleSelectChange}
                onChange={this.handleSelectChange}
              >
                <option>Selectionner</option>
                {listebacs
                  .sort(function (a, b) {
                    var nameA = a.intitule.toUpperCase(); // ignore upper and lowercase
                    var nameB = b.intitule.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }

                    // names must be equal
                    return 0;
                  })
                  .map((o, key) => (
                    <option key={key} value={o.code}>
                      {o.intitule}
                    </option>
                  ))}
              </select>
            </div>

            <button
              className="col-sm-6 btn-group"
              onClick={() => this.setState({ step: 2 })}
            >
              <span className="btn btn--ghost" id="next">
                Retour à l'étape précédente
              </span>
            </button>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.handleSaveStep3()}
            >
              <span className="btn" id="next">
                Voir le récapitulatif
              </span>
            </button>
          </div>
        </div>
      );
    } else if (this.state.step === 4) {
      return (
        <div className="mycampus-qcm">
          <div className="block" id="recap">
            <span className="subtitle subtitle--secondary mb0">
              Enquête SCOLEGE
            </span>
            <h1>Récapitulatif</h1>
            <p className="content mb-5">
              Merci d’avoir répondu à l’enquête. Vous trouvez ci-dessous un
              récapitulatif de vos réponses ainsi que les informations déjà
              connus par votre établissement scolaire. Si toute fois des données
              sont incorrectes, rapprochez-vous de votre a ssistant pédagogique.
            </p>
            <div id="accordion-1">
              <div
                id="headingOne"
                className="heading-one"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                1. Civilité de l'élève
                <FaChevronDown
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "2rem"
                  }}
                />
              </div>
              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion-1"
              >
                <ul className="c-profile-list">
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Numéro INE</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data != null &&
                              this.state.data.numeroIne != null
                              ? this.state.data.numeroIne
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Nom de famille d'usage</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data != null &&
                              this.state.data.nomFamilleUsage != null
                              ? this.state.data.nomFamilleUsage
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Prénom</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data != null &&
                              this.state.data.prenom != null
                              ? this.state.data.prenom
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Prénom 2</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.prenom2 != null
                            ? this.state.data.prenom2
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Prénom 3</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.prenom3 != null
                            ? this.state.data.prenom3
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Sexe</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data.sexe != null &&
                              listesexes.find(
                                o => o.code === this.state.data.sexe
                              ) != null
                              ? listesexes.find(
                                o => o.code === this.state.data.sexe
                              ).intitule
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Date de naissance</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data != null &&
                              this.state.data.dateNaissance != null
                              ? this.state.data.dateNaissance
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Commune de naissance</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.communeNaissance != null
                            ? this.state.data.communeNaissance
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Nationalité</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.nationalite != null &&
                            listepays.find(
                              o => o.code === this.state.data.nationalite
                            ) != null
                            ? listepays.find(
                              o => o.code === this.state.data.nationalite
                            ).intitule
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Commune de résidence</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.communeResidence != null
                            ? this.state.data.communeResidence
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div id="accordion-2">
              <div
                id="headingTwà"
                className="heading-two"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                2. Scolarité de l'année en cours
                <FaChevronDown
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "2rem"
                  }}
                />
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion-2"
              >
                <ul className="c-profile-list">
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Formation actuelle</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data.formationActuelle != null
                              ? this.state.data.formationActuelle
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Marque</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data.marque != null
                              ? this.state.data.marque
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Etablissement</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data.etablissement != null
                              ? this.state.data.etablissement
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Classe</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data.classe != null
                              ? this.state.data.classe
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 1 *</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option1 != null
                            ? this.state.data.option1
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 2</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option2 != null
                            ? this.state.data.option2
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 3</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option3 != null
                            ? this.state.data.option3
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 4</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option4 != null
                            ? this.state.data.option4
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 5</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option5 != null
                            ? this.state.data.option5
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 6</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option6 != null
                            ? this.state.data.option6
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Option 7</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.option7 != null
                            ? this.state.data.option7
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Statut scolaire</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          <i>
                            {" "}
                            {this.state.data.statutScolaire != null
                              ? this.state.data.statutScolaire
                              : ""}
                          </i>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Régime scolaire *</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.regimeScolaire != null &&
                            listeregimesscolaires.find(
                              o => o.code === this.state.data.regimeScolaire
                            ) != null
                            ? listeregimesscolaires.find(
                              o => o.code === this.state.data.regimeScolaire
                            ).intitule
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div id="accordion-3">
              <div
                id="headingThree"
                className="heading-three"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                3. Scolarité de l'année précédente
                <FaChevronDown
                  style={{
                    position: "absolute",
                    top: "1rem",
                    right: "2rem"
                  }}
                />
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion-3"
              >
                <ul className="c-profile-list">
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Formation année précédente *</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.formationAnneePrecedente != null
                            ? this.state.data.formationAnneePrecedente
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong>
                          {" "}
                          Département établissement année précédente *
                        </strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data
                              .departementEtablissementAnneePrecedente != null &&
                            listedepartements.find(
                              o =>
                                o.code ===
                                this.state.data
                                  .departementEtablissementAnneePrecedente
                            ) != null
                            ? listedepartements.find(
                              o =>
                                o.code ===
                                this.state.data
                                  .departementEtablissementAnneePrecedente
                            ).intitule
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Secteur établissement année précédente *</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.secteurEtablissementAnneePrecedente !=
                            null &&
                            listesecteurs.find(
                              o =>
                                o.code ===
                                this.state.data
                                  .secteurEtablissementAnneePrecedente
                            ) != null
                            ? listesecteurs.find(
                              o =>
                                o.code ===
                                this.state.data
                                  .secteurEtablissementAnneePrecedente
                            ).intitule
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="c-profile-list__item">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <strong> Type de baccalauréat ou équivalence</strong>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <p>
                          {" "}
                          {this.state.data != null &&
                            this.state.data.typeBac != null &&
                            listebacs.find(
                              o => o.code === this.state.data.typeBac
                            ) != null
                            ? listebacs.find(
                              o => o.code === this.state.data.typeBac
                            ).intitule
                            : ""}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <button
              className="col-sm-6 btn-group"
              onClick={() => this.setState({ step: 1 })}
            >
              <span className="btn btn--ghost" id="next">
                Modifier mes informations
              </span>
            </button>
            <Link className="col-sm-6 btn-group" to="/">
              <span className="btn" id="next">
                Retourner vers l'écran d'accueil
              </span>
            </Link>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
export default EnqueteScolege;

/*

 */
