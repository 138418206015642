import React from "react";
import Toast from "./common/Toast";
import apis from "../config/apis";
import apisEndpoints from "../config/apisEndpoints";


import { getAccessToken } from '../authProvider';

class CTAScolege extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        statut: "En attente",
        date: "",
      loading: true
    };
  }

  async componentDidMount() {
    var backendAccessToken = await getAccessToken();
    fetch(
      apis.find(o => o.app === "backEnd").url +
      apisEndpoints.find(
        o => o.module === "enquetes" && o.feature === "scolegeStatus"
      ).endpoint,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ` + backendAccessToken
        }
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(res => {
          this.setState({ statut: res ? res.statut : null, loading: false, date: res.deadline });
      });

  }

  render() {
    var text = "";
    var title = "";
    if (this.state.statut === "En attente") {
      title = "Questionnaire en attente : ";
      text = `Questionnaire « SCOLEGE » non rempli, merci de bien vouloir le compléter avant le ${this.state.date}.`;
    }
    if (this.state.statut === "Incomplet") {
      title = "Questionnaire incomplet : ";
        text = `Questionnaire « SCOLEGE » incomplet, merci de bien vouloir renseigner tous les champs obligatoires avant le ${this.state.date}.`;
    }
    if (this.state.statut === "Complet") {
      title = "Questionnaire rempli : ";
        text = `Questionnaire « SCOLEGE » rempli, vous pouvez revenir consulter et modifier vos informations à tout moment d’ici le ${this.state.date}.`;
    }
    return !this.state.loading && this.state.statut != null ? (
      <Toast title={title} text={text} buttonText="Compléter" route="/scolege" />
    ) : (
        ""
      );
  }
}
export default CTAScolege;
