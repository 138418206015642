import React from "react";
import Alert from "./common/Alert";
import APIs from "../config/apis";
import { getAccessToken } from '../authProvider';

class CTAAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alerts: null
    };
  }

  async componentDidMount() {
    var backendAccessToken = await getAccessToken();
    fetch(APIs.find(o => o.app === "backEnd").url + "alerte/", {
      method: "GET",
      headers: {
        Authorization: `Bearer ` + backendAccessToken
      }
    }).then((response) => {
      if (response.status === 401) {
        throw new Error(response.status)
      }
      return response
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(res => {
        this.setState({ alerts: res });
      }).catch(error => {
        localStorage.clear();
        window.location.reload();
      });

  }

  render() {
    if (this.state.alerts && this.state.alerts.length > 0) {
      return (
        <div>
          {this.state.alerts.map((alert, key) => (
            <Alert key={key} text={alert.content} defilement={alert.defilement} />
          ))}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default CTAAlert;
