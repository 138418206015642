import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className={"l-footer l-footer--pigier"}>
        <div className="l-footer-help">
          <div className="container">
            <div className="l-footer-help__content">
              <h2 className="h2"> Besoin d'aide ?</h2>
              <p>
                Rapprochez-vous de votre assitant pédagogique afin de trouver
                réponses à vos questions.
              </p>
            </div>
          </div>
        </div>
        <div className="l-footer-version">
          <div className="container">
            <div className="l-footer-version__content">
              <div>Copyright © Eduservices – All rights reserved</div>
              <div>v0.4.8</div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
