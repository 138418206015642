export const marques = [
  {
    css: "aftec",
    code: "AFTEC",
    logo: "aftec"
  },
  {
    css: "capvers",
    code: "CAP VERS",
    logo: "capvers"
  },
  {
    css: "esicad",
    code: "ESICAD",
    logo: "esicad"
  },
  {
    css: "espl",
    code: "ESPL",
    logo: "espl"
  },
  {
    css: "ihecf",
    code: "IHECF",
    logo: "ihecf"
  },
  {
    css: "ipac",
    code: "IPAC",
    logo: "ipac"
  },
  {
    css: "ipac-bachelor",
    code: "IPAC BACHELOR FACTORY",
    logo: "ipacBachelorFactory"
  },
  {
    css: "ipac-design",
    code: "IPAC DESIGN",
    logo: "ipacDesign"
  },
  {
    css: "ipac",
    code: "IPAC E-LEARNING",
    logo: "ipacELearning"
  },
  {
    css: "iscom",
    code: "ISCOM",
    logo: "iscom"
  },
  {
    css: "isifa",
    code: "ISIFA PLUS VALUES",
    logo: "isifa"
  },
  {
    css: "mbway",
    code: "MBWAY",
    logo: "mbway"
  },
  {
    css: "mydigitalschool",
    code: "MYDIGITALSCHOOL",
    logo: "mydigitaluniversity"
  },
  {
    css: "pigier",
    code: "PIGIER",
    logo: "pigier"
  },
  {
    css: "pigier",
    code: "PIGIER CREATION",
    logo: "pigierCreation"
  },
  {
    css: "pigier",
    code: "PIGIER PERFORMANCE",
    logo: "pigierPerformance"
  },
  {
    css: "tunon",
    code: "TUNON",
    logo: "tunon"
  },
  {
    css: "win",
    code: "WIN",
    logo: "win"
  },
  {
    css: "studio-m",
    code: "STUDIO M",
    logo: "studio-m"
  }
];
