import React from "react";
import { allapps } from "../config/apps";
import { isIOS, isSafari } from "react-device-detect";
import APIs from "../config/apis";

import { getAccessToken } from "../authProvider";

class MyFavoriteApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      application: this.props.application,
      isNew: false,
      isClicked: false
    };
    this.appConnect = this.appConnect.bind(this);
  }
  componentDidMount() {
    this.setState({ application: this.props.application });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.application !== this.props.application) {
      this.setState({ application: this.props.application });
    }
  }

  async saveCnx(e) {
    var backendAccessToken = await getAccessToken();
    e.preventDefault();
    var id = this.state.application.id;
    fetch(APIs.find(o => o.app === "backEnd").url + "aadapps/" + id, {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + backendAccessToken
      }
    }).then(res => {
      var link =
        "https://myapps.microsoft.com/signin/" +
        this.state.application.appDisplayName +
        "/" +
        this.state.application.appId +
        "?tenantId=8a2f18a9-8f0e-4f76-a585-9b07b3a38dea" 
        //+ (this.props.account ? this.props.account.tenantId : "");

      if (isIOS || isSafari) {
        window.location.assign(link, "_blank");
      } else {
        window.open(link, "_blank");
      }
    });
  }

  appConnect(e) {
    this.saveCnx(e);
  }

  render() {
    if (this.state.application) {
      return (
        <div className="col-12 col-md-4 mb-5">
          <button
            style={{ width: "100%" }}
            className="h-link-card d-block"
            onClick={e => {
              this.appConnect(e);
            }}
            target="_blank"
          >
            <span className="badge badge-left badge-danger">
              {allapps.find(o => o.id === this.state.application.appId) && allapps.find(o => o.id === this.state.application.appId).isNew
                ? "Nouveau"
                : ""}
            </span>
            <div className="card btn-card h-100">
              <div className="card-img-group">
                <img
                  className="card-img-square"
                  src={ this.state.application.info &&
                    this.state.application.info.logoUrl != null
                      ? this.state.application.info.logoUrl
                      : require("../assets/img/EduservicesLogo.jpg")
                  }
                  alt=""
                />
              </div>
              <div className="card-body text-center">
                <h3 className="h3">{this.state.application.appDisplayName}</h3>
                <p className="card-text">
                  {allapps.find(o => o.id === this.state.application.appId) ?
                    allapps.find(o => o.id === this.state.application.appId)
                      .description : ""
                  }
                </p>
              </div>
            </div>
          </button>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default MyFavoriteApp;
