import React from "react";
import MyApp from "./MyApp";
class MyApps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applications: this.props.applications,
      toolSelected: "all",
      tools: [],
      search: ""
    };
  }

  componentDidMount() {
    this.setState({ applications: this.props.applications, tools: this.props.tools })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applications !== this.props.applications) {
      this.setState({ applications: this.props.applications })
    }

    if (prevProps.tools !== this.props.tools) {
      this.setState({ tools: this.props.tools })
    }
  }

  render() {
    return (
      <section className="c-section-card">
        <h2 className="h2">Mes outils</h2>
        <p className="card-text mb-5">
          Liste des autres outils disponibles. La liste ci-dessous est
          personnalisée en fonction de votre cursus.
        </p>

        <div className="row pl-4 m-1 mb-3">
          <div className="row">
            <input type="text" placeholder="Recherche" value={this.state.search} style={{borderRadius: "0.375rem", borderColor: "rgb(209, 213, 219)", borderWidth: "1px", marginRight: "2rem"}} onChange={(event) => {
              this.setState({search: event.target.value})}} />
            <span key={"all"} style={this.state.toolSelected === "all" ? {
              display: "inline-flex", alignItems: "center", paddingLeft: "0.75rem",
              paddingRight: "0.75rem", paddingTop: "0.5rem", paddingBottom: "0.5rem", borderRadius: "9999px", fontSize: "1.25rem",
              fontWeight: "600", backgroundColor: "rgb(219, 234, 254)", color: "rgb(30, 64, 175)",
              marginRight: "2rem", marginBottom: "1rem", cursor: "pointer"

            } : {
              display: "inline-flex", alignItems: "center", paddingLeft: "0.75rem",
              paddingRight: "0.75rem", paddingTop: "0.125rem", paddingBottom: "0.125rem", borderRadius: "9999px", fontSize: "1.25rem",
              fontWeight: "600", backgroundColor: "rgba(243, 244, 246, 1)", color: "rgba(31, 41, 55, 1)",
              marginRight: "2rem", marginBottom: "1rem", cursor: "pointer"
            }} onClick={() => { this.setState({ toolSelected: "all" }) }}>
              Tout
            </span>
            {this.state.tools ? this.state.tools.map(tool => <span key={tool.id} style={this.state.toolSelected === tool.id ? {
              display: "inline-flex", alignItems: "center", paddingLeft: "0.75rem",
              paddingRight: "0.75rem", paddingTop: "0.5rem", paddingBottom: "0.5rem", borderRadius: "9999px", fontSize: "1.25rem",
              fontWeight: "600", backgroundColor: "rgb(219, 234, 254)", color: "rgb(30, 64, 175)",
              marginRight: "2rem", marginBottom: "1rem", cursor: "pointer"

            } : {
              display: "inline-flex", alignItems: "center", paddingLeft: "0.75rem",
              paddingRight: "0.75rem", paddingTop: "0.125rem", paddingBottom: "0.125rem", borderRadius: "9999px", fontSize: "1.25rem",
              fontWeight: "600", backgroundColor: "rgba(243, 244, 246, 1)", color: "rgba(31, 41, 55, 1)",
              marginRight: "2rem", marginBottom: "1rem", cursor: "pointer"
            }} onClick={() => { this.setState({ toolSelected: tool.id }) }}>
              {tool.label}
            </span>) : ""}
          </div>
        </div>
        <div className="row">
          {this.state.applications ? this.state.applications.filter(app => {
            if(this.state.toolSelected === "all"){
              return app.appDisplayName.toString()
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "").includes(this.state.search.toString()
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""))
            } else {
              return app.toolId === this.state.toolSelected && app.appDisplayName.toString()
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "").includes(this.state.search.toString()
              .toUpperCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""))
            }
          }).map((application, key) =>
            <MyApp
              key={key}
              application={application}
              account={this.props.account}
            />
          )
            : "Vous n'avez pas encore d'applications favorites !"}
        </div>
      </section>
    );
  }
}

export default MyApps;
