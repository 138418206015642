import React from "react";
import BackToHomeButton from "./BackToHomeButton";

class CardTitle extends React.Component {
  render() {
    return (
      <>
        <h2 className="h2">
          <BackToHomeButton />
          {this.props.title}
        </h2>
        {this.props.subtitle != null ? (
          <p className="card-text mb-5">{this.props.subtitle}</p>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default CardTitle;
