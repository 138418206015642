import React from "react";
import { Link } from "react-router-dom";
import CardTitle from "../layout/CardTitle";
import APIs from "../config/apis";
import { getAccessToken } from '../authProvider';

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: null,
      id: this.props.match.params.id,
      open: this.props.match.params.id? true : false
    };
    window.scrollTo(0, this.state.id);
    this.handleClickFaq = this.handleClickFaq.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.id != null &&
      prevProps.match.params.id !== this.props.match.params.id
    ) {
      this.setState({ id: this.props.match.params.id, open: true });
    }
  }

  async componentDidMount() {
    var backendAccessToken = await getAccessToken();

    fetch(APIs.find(o => o.app === "backEnd").url + "faq", {
      method: "GET",
      headers: {
        Authorization: `Bearer ` + backendAccessToken
      }
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(res => {
        this.setState({ faqs: res });
      });
  }

  handleClickFaq = id => {
    window.scrollTo(0, id);
    if (this.state.id === id.toString()) {
      this.setState({ open: !this.state.open });
    } else {
      this.setState({ id });
    }
  };

  render() {
    return (
      <div className="card border-md-1">
        <div className="card-body">
          <section className="c-section-card">
            <CardTitle
              title="Questions fréquentes"
              subtitle="Retrouvez ci-dessous les réponses aux questions fréquemment
                    posées."
            />
            <div className="accordion" id="accordion-question">
              {this.state.faqs != null
                ? this.state.faqs.map((faq, key) => (
                  <div
                    className="card border-bottom"
                    key={key}
                    ref={faq.id}
                    style={{ paddingBottom: "4px" }}
                  >
                    <div className="card-header mx-minus" id="heading-question-1">
                      <Link
                        onClick={() => this.handleClickFaq(faq.id)}
                        to={"/faq/" + faq.id}
                        className={
                          this.state.id === faq.id.toString() && this.state.open
                            ? "btn-accordion-aside d-flex justify-content-between align-items-center"
                            : "btn-accordion-aside d-flex justify-content-between align-items-center collapsed"
                        }
                        type="button"
                        style={{ width: "100%" }}
                      >
                        <span>{faq.subject}</span>
                        <svg className="icon icon-chevron">
                          <use href="#icon-chevron-pigier"></use>
                        </svg>
                      </Link>
                    </div>
                    <div
                      className={
                        this.state.id === faq.id.toString() && this.state.open ? "collapse show" : "collapse"
                      }
                      style={{
                        borderStyle: "solid",
                        borderRadius: "6px",
                        backgroundColor: "#F5F5F5",
                        borderColor: "#DDDDDD",
                        paddingLeft: "7px",
                        paddingRight: "7px"
                      }}
                    >
                      <div className="card-body">
                        <p className="desc-accordion-aside">
                          <div
                            key={faq.id}
                            dangerouslySetInnerHTML={{ __html: faq.content }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                ))
                : ""}
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default Faq;

/*

 */
