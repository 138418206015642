import React from "react";
import Container from "./layout/Container";
import Loading from "./authentication/Loading";
import Error from "./authentication/Error";

import { AuthProvider, msalClient } from './authProvider';
import { AuthProviderState } from 'msal-react-v2/lib/AuthProvider'
import "./assets/sass/main.scss";
function App() {
  return <AuthProvider
    render={({ state, account }) => {
      switch (state) {
        case AuthProviderState.Success:
          return <Container logout={msalClient} account={account} />;
        case AuthProviderState.Loading:
          return <Loading />;
        default:
          return <Error />;
      }
    }
    }
  />
}

export default App;
