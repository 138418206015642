import React from "react";
import Aside from "../layout/Aside";
import Footer from "../layout/Footer";
import Home from "../pages/Home";
import Profil from "../pages/Profil";
import EnqueteSise from "../pages/EnqueteSise";
import EnqueteScolege from "../pages/EnqueteScolege";
import "../css/main.css";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import SweetAlert from "sweetalert-react";
import "sweetalert/dist/sweetalert.css";
import { marques } from "../config/marques";
import { formations } from "../config/formations";
import Logo from "./Logo";
import FaqAside from "./FaqAside";
import Faq from "../pages/Faq";
import NewsAside from "./NewsAside";
import News from "../pages/News";

import { msalClient, graphClient } from '../authProvider';

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectEnabled: true,
      userName: null,
      me: null,
      marque: null,
      formation: null,
      mails: 0,
      alertLeave: false,
      displayMobileMenu: false
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMobileMenu: true });
  }

  hideDropdownMenu() {
    this.setState({ displayMobileMenu: false });
  }

  async componentDidMount() {
    await graphClient.api("/me").version("beta").get()
    .then(me => {
      if (me) {
        this.setState({ me });
        if (me.jobTitle !== "Etudiant")
          return;
        if (me.extension_0fb3318848f64e27bb07a8a91b147048_eduservices_CompanyName) {
          var marque = marques.find(o => o.code === me.extension_0fb3318848f64e27bb07a8a91b147048_eduservices_CompanyName);
          if (marque) {
            this.setState({ marque });
          }
        }
        if (me.extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel) {
          var formation = formations.find(o => o.code === me.extension_0fb3318848f64e27bb07a8a91b147048_eduservices_FormationLevel);
          if (formation) {
            this.setState({ formation });
          }
        }
      }
    })
    .catch(ex => {
      console.log(ex);
    });
  }

  render() {
    return (
      <div id={this.state.marque != null ? this.state.marque.css : ""}>
        <SweetAlert
          show={this.state.alertLeave}
          title="Déconnexion"
          text="Etes-vous sûr de vouloir vous déconnecter du portail MyCampus ?"
          showCancelButton
          type="warning"
          onConfirm={() => {
            this.setState({ alertLeave: false });
            msalClient.logout();
          }}
          confirmButtonColor="#000000"
          onCancel={() => {
            this.setState({ alertLeave: false });
          }}
          cancelButtonText="Revenir au portail"
          confirmButtonText="Confirmer"
          onOutsideClick={() => this.setState({ alertLeave: false })}
        />

        <BrowserRouter>
          <button className="btn-top-page d-md-none btn-top-page--pigier">
            <img
              src={require("../assets/img/upchevron.png")}
              alt=""
              width="20"
            />
          </button>
          <div
            className={
              this.state.displayMobileMenu ? "l-overlow" : "l-overlow h-hidden"
            }
          />
          <header className="l-header l-header--pigier">
            <div className="container">
              <nav className="c-navbar">
                <button
                  className="btn-header-menu d-md-none"
                  type="button"
                  onClick={e => this.showDropdownMenu(e)}
                >
                  <svg
                    className="icon icon-pigier-white"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 21 14"
                  >
                    <path
                      d="M19.3,6.2c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8c0,0,0,0,0,0H0.8C0.4,7.8,0,7.4,0,7s0.4-0.8,0.8-0.8 c0,0,0,0,0,0H19.3z M19.3,0c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8H0.8C0.4,1.6,0,1.3,0,0.8S0.4,0,0.8,0H19.3z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                    <path
                      d="M19.3,12.2c0.4,0,0.8,0.4,0.8,0.8c0,0.4-0.4,0.8-0.8,0.8c0,0,0,0,0,0H0.8C0.4,13.8,0,13.4,0,13s0.4-0.8,0.8-0.8 c0,0,0,0,0,0H19.3z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                  </svg>
                </button>
                <div className="c-navbar__link" href="#">
                  <h1 className="h1">
                    <Link to="/">
                      <Logo marque={this.state.marque} />
                    </Link>
                    <span className="c-navbar__link__text">MyCampus</span>
                  </h1>
                </div>
                <div>
                  <button
                    className="btn-header-login d-none d-md-block"
                    onClick={() => {
                      this.setState({ alertLeave: true });
                    }}
                  >
                    Me déconnecter
                  </button>
                </div>
              </nav>
            </div>
          </header>
          <div
            className={
              this.state.displayMobileMenu
                ? "l-header-menu-mobile l-header-menu-mobile--pigier"
                : "l-header-menu-mobile h-hidden l-header-menu-mobile--pigier"
            }
          >
            <div className="c-header-menu-mobile__top">
              <div className="container">
                <button
                  className="btn-close"
                  type="button"
                  onClick={e => this.hideDropdownMenu(e)}
                >
                  <svg
                    className="icon icon-pigier-close"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M9.218 7.813l5.968 5.969a.813.813 0 1 1-1.15 1.15L8.067 8.964 2.1 14.932a.813.813 0 1 1-1.15-1.15l5.968-5.969L.95 1.845a.813.813 0 0 1 1.15-1.15l5.968 5.968L14.036.695a.813.813 0 1 1 1.15 1.15L9.218 7.813z"
                      fill="#FFF"
                      fillRule="nonzero"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <nav className="c-header-nav-mobile">
              <ul className="c-header-nav-mobile__list">
                <li className="c-header-nav-mobile__item">
                  <ul>
                    <li>
                      <div className="c-header-nav-mobile__main-link" href="#">
                        <div className="container">
                          <Link to="/">Mes outils</Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a
                        className="c-header-nav-mobile__main-link"
                        href="https://outlook.office.com/owa"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="container position-relative">
                          Mes messages
                          <span className="badge badge-danger position-absolute ml-2">
                            {this.state.mails}
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <div className="c-header-nav-mobile__main-link" href="#">
                        <div className="container">
                          <Link to="/profil">Mon profil</Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="c-header-nav-mobile__main-link" href="#">
                        <div className="container">
                          <Link to="/news">Actualités</Link>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="c-header-nav-mobile__main-link" href="#">
                        <div className="container">
                          <Link to="/faq">Questions fréquentes</Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="c-header-nav-mobile__item">
                  <div
                    className="c-header-nav-mobile__main-link"
                    onClick={() => {
                      this.setState({ alertLeave: true });
                    }}
                  >
                    <div className="container">
                      <button>Me déconnecter</button>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>

          <div className="container font--school container" role="main">
            <div className="row">
              <aside className="d-none col-md-3 d-md-block">
                <div>
                  <Aside me={this.state.me} />
                  <NewsAside />
                  <FaqAside />
                </div>
              </aside>
              <div className="col-12 col-md-9">
                <div>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Home formation={this.state.formation} account={this.props.account} />}
                    />
                    <Route
                      path="/profil"
                      render={() => <Profil user={this.state.me} />}
                    />
                    <Route
                      path="/faq/:id"
                      component={Faq}
                      render={() => <Faq />}
                    />
                    <Route
                      exact
                      path="/faq"
                      component={Faq}
                      render={() => <Faq />}
                    />
                    <Route
                      path="/news/:id"
                      component={News}
                      render={() => <News />}
                    />
                    <Route
                      exact
                      path="/news"
                      component={News}
                      render={() => <News />}
                    />
                    <Route
                      path="/sise"
                      render={() => <EnqueteSise user={this.state.me} />}
                    />
                    <Route
                      path="/scolege"
                      render={() => <EnqueteScolege user={this.state.me} />}
                    />
                    <Route
                      exact
                      path="*"
                      render={() => <Home formation={this.state.formation} account={this.props.account} />}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="0"
            height="0"
            style={{ position: "absolute" }}
          >
            <symbol id="icon-chevron" viewBox="0 0 14 9">
              <title>Path 2 Copy 2</title>
              <path
                d="M13 1.058L6.917 7 1 1"
                stroke="#000"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
              />
            </symbol>

            <symbol id="icon-chevron-pigier" viewBox="0 0 14 9">
              <title>Path 2 Copy 2</title>
              <path
                d="M13 1.058L6.917 7 1 1"
                stroke="#02559b"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
              />
            </symbol>

            <symbol id="icon-chevron-white" viewBox="0 0 14 9">
              <title>Path 2 Copy 2</title>
              <path
                d="M13 1.058L6.917 7 1 1"
                stroke="#fff"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
              />
            </symbol>

            <symbol id="icon-close" viewBox="0 0 16 16">
              <title>Combined Shape</title>
              <path
                d="M9.218 7.813l5.968 5.969a.813.813 0 1 1-1.15 1.15L8.067 8.964 2.1 14.932a.813.813 0 1 1-1.15-1.15l5.968-5.969L.95 1.845a.813.813 0 0 1 1.15-1.15l5.968 5.968L14.036.695a.813.813 0 1 1 1.15 1.15L9.218 7.813z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-eye" viewBox="0 0 25 15">
              <title>Atom/eye</title>
              <path
                d="M12.5 0C7.723 0 3.392 2.63.196 6.903c-.261.35-.261.839 0 1.189C3.392 12.37 7.723 15 12.5 15s9.108-2.63 12.304-6.903c.261-.35.261-.839 0-1.189C21.608 2.63 17.277 0 12.5 0zm.343 12.781c-3.171.201-5.79-2.43-5.59-5.626.164-2.635 2.286-4.772 4.904-4.936 3.171-.201 5.79 2.43 5.59 5.626-.169 2.63-2.291 4.767-4.904 4.936zm-.159-2.44a2.836 2.836 0 0 1-3.007-3.026 2.853 2.853 0 0 1 2.644-2.662 2.836 2.836 0 0 1 3.007 3.027c-.092 1.426-1.238 2.574-2.644 2.661z"
                fill="#000"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-help" viewBox="0 0 20 20">
              <title>Picto / Help</title>
              <g fillRule="nonzero" fill="none">
                <path
                  d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0z"
                  fill="#5C596D"
                />
                <path
                  d="M10 14.23a.384.384 0 0 0-.385.385v.77a.384.384 0 1 0 .77 0v-.77a.384.384 0 0 0-.385-.384zM10.043 3.462H10c-.814 0-1.579.313-2.16.886a3.055 3.055 0 0 0-.916 2.19.384.384 0 1 0 .77 0c0-.622.244-1.206.687-1.643a2.273 2.273 0 0 1 1.653-.664c1.216.017 2.257 1.059 2.274 2.274A2.297 2.297 0 0 1 11.25 8.48c-1.023.66-1.634 1.826-1.634 3.12v1.093a.384.384 0 1 0 .77 0V11.6c0-1.016.49-1.963 1.281-2.474a3.062 3.062 0 0 0 1.41-2.63 3.098 3.098 0 0 0-3.033-3.033z"
                  fill="#FFF"
                />
              </g>
            </symbol>

            <symbol id="icon-help-white" viewBox="0 0 20 20">
              <title>help-white</title>
              <path
                d="M10 1.307c4.963 0 9 4.059 9 9.048s-4.037 9.047-9 9.047-9-4.058-9-9.047c0-4.99 4.037-9.048 9-9.048zm0 12.876a.347.347 0 0 0-.346.348v.696a.347.347 0 1 0 .692 0v-.696a.347.347 0 0 0-.346-.348zm.04-9.744H10a2.74 2.74 0 0 0-1.944.802 2.772 2.772 0 0 0-.825 1.982.347.347 0 1 0 .692 0c0-.563.22-1.091.619-1.487.399-.395.93-.614 1.488-.6 1.094.015 2.031.957 2.047 2.057a2.081 2.081 0 0 1-.953 1.786c-.92.597-1.47 1.652-1.47 2.823v.989a.347.347 0 1 0 .692 0v-.99c0-.919.442-1.776 1.154-2.237a2.774 2.774 0 0 0 1.269-2.38c-.02-1.492-1.246-2.724-2.73-2.745z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-information" viewBox="0 0 29 29">
              <title>Picto / Information</title>
              <g fill="none" fillRule="evenodd">
                <path
                  d="M14.5 0C6.505 0 0 6.505 0 14.5S6.505 29 14.5 29 29 22.495 29 14.5 22.495 0 14.5 0z"
                  fill="#004FB5"
                />
                <path
                  d="M14.776 6C13.796 6 13 6.797 13 7.776s.796 1.775 1.776 1.775c.979 0 1.775-.796 1.775-1.775 0-.98-.796-1.776-1.775-1.776zM14.796 12.429c-.654 0-1.184.596-1.184 1.331v7.99c0 .735.53 1.332 1.184 1.332.654 0 1.184-.597 1.184-1.332v-7.99c0-.735-.53-1.331-1.184-1.331z"
                  fill="#FFF"
                />
              </g>
            </symbol>

            <symbol id="icon-menu" viewBox="0 0 21 8">
              <title>Combined Shape</title>
              <path
                d="M19.321 6.187a.813.813 0 1 1 0 1.626H.813a.813.813 0 1 1 0-1.626h18.508zm0-6.17a.813.813 0 1 1 0 1.627H.813a.813.813 0 0 1 0-1.627h18.508z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-non-valide" viewBox="0 0 15 15">
              <title>Picto / Check / Non Valide</title>
              <path
                d="M7.494 0a7.494 7.494 0 1 0 0 14.989A7.494 7.494 0 0 0 7.494 0zM6.12 11.344l-3.3-3.3 1.1-1.1 2.2 2.2 4.95-4.95 1.1 1.1-6.05 6.05z"
                fill="#D8D8D8"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-pigier" viewBox="0 0 739 207">
              <title>PIGIER</title>
              <path
                d="M45.213 57.399H.023v18.47h45.19c8.152 0 14.729 6.588 14.729 14.684 0 8.118-6.577 14.695-14.729 14.695H0v56.949h20.792v-38.49h24.421c19.598 0 35.499-13.568 35.499-33.154 0-19.576-15.901-33.154-35.499-33.154zm49.99 104.797h21.262V57.398H95.203v104.798zM272.12 57.398h-21.262v8.085c0 47.38-.022 74.213-.022 88.618v8.095h21.284V57.398zm196.116 33.166c0-19.587-15.969-33.165-35.71-33.165H395.25v18.47h37.275c8.173 0 14.817 6.588 14.817 14.695s-6.644 14.684-14.817 14.684H395.25v14.695l42.366 41.953h29.58l-38.558-38.19h3.887c19.741 0 35.71-13.567 35.71-33.142h.001zm-335.06 19.09c0 30.116 24.41 52.238 54.56 52.238h36.526v-56.235h-45.437v18.47h24.656v19.329h-15.745c-18.67 0-33.812-15.142-33.812-33.802 0-18.659 15.142-33.79 33.812-33.79h36.526v-18.47h-36.526c-30.15 0-54.56 22.155-54.56 52.26zm213.921 52.238h28.073v-18.436h-28.073c-13.404 0-25.002-7.797-30.485-19.093h54.549v-19.006h-57.638c2.119-16.63 16.35-29.493 33.574-29.493h28.106v-18.47h-28.106c-30.16 0-54.615 22.155-54.615 52.26 0 30.116 24.455 52.238 54.615 52.238zm389.6-79.941h-19.698a85.838 85.838 0 0 1 2.669 21.217c0 46.475-37.274 83.972-83.09 83.972a83.04 83.04 0 0 1-14.416-1.251v19.419a100.65 100.65 0 0 0 14.415 1.038c56.581 0 102.286-46.285 102.286-103.178 0-7.27-.76-14.36-2.167-21.217h.001zm-186.902 44.89a84.75 84.75 0 0 1-3.361-23.673c0-46.453 37.296-83.962 83.09-83.962V0C572.954 0 527.24 46.285 527.24 103.168c0 8.162.949 16.09 2.725 23.673h19.831-.001zm92.979 37.69l.006-28.272c24.437-3.09 46.223-19.296 46.223-42.747 0-26.163-27.067-43.315-54.705-43.315-3.462 0-8.732.257-12.138.803v66.373c-15.494-3.748-24.521-14.393-24.521-23.861V81.229h-18.057v12.283c0 22.275 19.658 38.015 42.578 42.185v28.833h20.614v.001zm.022-95.642c17.721 2.86 28.162 14.416 28.162 24.623 0 10.189-10.461 21.74-28.173 24.591l.011-49.214z"
                fill="#000"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-pigier-white" viewBox="0 0 739 207">
              <title>PIGIER</title>
              <path
                d="M45.213 57.399H.023v18.47h45.19c8.152 0 14.729 6.588 14.729 14.684 0 8.118-6.577 14.695-14.729 14.695H0v56.949h20.792v-38.49h24.421c19.598 0 35.499-13.568 35.499-33.154 0-19.576-15.901-33.154-35.499-33.154zm49.99 104.797h21.262V57.398H95.203v104.798zM272.12 57.398h-21.262v8.085c0 47.38-.022 74.213-.022 88.618v8.095h21.284V57.398zm196.116 33.166c0-19.587-15.969-33.165-35.71-33.165H395.25v18.47h37.275c8.173 0 14.817 6.588 14.817 14.695s-6.644 14.684-14.817 14.684H395.25v14.695l42.366 41.953h29.58l-38.558-38.19h3.887c19.741 0 35.71-13.567 35.71-33.142h.001zm-335.06 19.09c0 30.116 24.41 52.238 54.56 52.238h36.526v-56.235h-45.437v18.47h24.656v19.329h-15.745c-18.67 0-33.812-15.142-33.812-33.802 0-18.659 15.142-33.79 33.812-33.79h36.526v-18.47h-36.526c-30.15 0-54.56 22.155-54.56 52.26zm213.921 52.238h28.073v-18.436h-28.073c-13.404 0-25.002-7.797-30.485-19.093h54.549v-19.006h-57.638c2.119-16.63 16.35-29.493 33.574-29.493h28.106v-18.47h-28.106c-30.16 0-54.615 22.155-54.615 52.26 0 30.116 24.455 52.238 54.615 52.238zm389.6-79.941h-19.698a85.838 85.838 0 0 1 2.669 21.217c0 46.475-37.274 83.972-83.09 83.972a83.04 83.04 0 0 1-14.416-1.251v19.419a100.65 100.65 0 0 0 14.415 1.038c56.581 0 102.286-46.285 102.286-103.178 0-7.27-.76-14.36-2.167-21.217h.001zm-186.902 44.89a84.75 84.75 0 0 1-3.361-23.673c0-46.453 37.296-83.962 83.09-83.962V0C572.954 0 527.24 46.285 527.24 103.168c0 8.162.949 16.09 2.725 23.673h19.831-.001zm92.979 37.69l.006-28.272c24.437-3.09 46.223-19.296 46.223-42.747 0-26.163-27.067-43.315-54.705-43.315-3.462 0-8.732.257-12.138.803v66.373c-15.494-3.748-24.521-14.393-24.521-23.861V81.229h-18.057v12.283c0 22.275 19.658 38.015 42.578 42.185v28.833h20.614v.001zm.022-95.642c17.721 2.86 28.162 14.416 28.162 24.623 0 10.189-10.461 21.74-28.173 24.591l.011-49.214z"
                fill="#fff"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-play" viewBox="0 0 63 63">
              <title>play</title>
              <g fill="none" fillRule="evenodd">
                <path
                  d="M31.5 2C15.208 2 2 15.208 2 31.5S15.208 61 31.5 61 61 47.792 61 31.5 47.792 2 31.5 2z"
                  stroke="#FFF"
                  strokeWidth="4"
                />
                <path fill="#FFF" d="M43.2 32.4l-18 10.8V21.6z" />
              </g>
            </symbol>

            <symbol id="icon-profil" viewBox="0 0 20 17">
              <title>Picto / Profil</title>
              <g fillRule="nonzero" fill="none">
                <path
                  d="M16.669 16.526v-2.193A4.339 4.339 0 0 0 12.335 10H7.668a4.339 4.339 0 0 0-4.334 4.333v2.193a9.333 9.333 0 0 1 .09-13.154 9.336 9.336 0 0 1 13.155 0 9.333 9.333 0 0 1 .09 13.154z"
                  fill="#5C596D"
                />
                <path
                  d="M10.182 2.727a3.182 3.182 0 1 0 0 6.364 3.182 3.182 0 0 0 0-6.364z"
                  fill="#FFF"
                />
              </g>
            </symbol>

            <symbol id="icon-profil-white" viewBox="0 0 20 20">
              <title>profil-white</title>
              <path
                d="M16.669 17.866v-2.193a4.339 4.339 0 0 0-4.334-4.333H7.668a4.339 4.339 0 0 0-4.334 4.333v2.192a9.333 9.333 0 0 1 .09-13.154 9.336 9.336 0 0 1 13.155 0 9.333 9.333 0 0 1 .09 13.154zm-6.487-13.8a3.182 3.182 0 1 0 0 6.364 3.182 3.182 0 0 0 0-6.363z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-search" viewBox="0 0 25 25">
              <title>Picto / Search</title>
              <path
                d="M14.657 14.657L24 24l-9.343-9.343A8 8 0 1 1 3.343 3.343a8 8 0 0 1 11.314 11.314z"
                stroke="#333"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </symbol>

            <symbol id="icon-valide" viewBox="0 0 15 15">
              <title>Picto / Check / Valide</title>
              <path
                d="M7.494 0a7.494 7.494 0 1 0 0 14.989A7.494 7.494 0 0 0 7.494 0zM6.12 11.344l-3.3-3.3 1.1-1.1 2.2 2.2 4.95-4.95 1.1 1.1-6.05 6.05z"
                fill="#6AC259"
                fillRule="nonzero"
              />
            </symbol>

            <symbol id="icon-warning" viewBox="0 0 29 29">
              <title>Picto / Warning</title>
              <path
                d="M14.452 0C6.471 0 0 6.47 0 14.452s6.47 14.452 14.452 14.452 14.452-6.469 14.452-14.452C28.904 6.471 22.434 0 14.452 0zm.001 6.338c-1.035 0-1.818.54-1.818 1.413v8.003c0 .873.783 1.412 1.818 1.412 1.01 0 1.818-.561 1.818-1.412V7.75c0-.852-.808-1.413-1.818-1.413zm0 12.63c-.994 0-1.804.81-1.804 1.805 0 .994.81 1.803 1.804 1.803.995 0 1.803-.81 1.803-1.803 0-.995-.808-1.804-1.803-1.804z"
                fill="#FF5A5F"
                fillRule="evenodd"
              />
            </symbol>
          </svg>
        </BrowserRouter>
      </div>
    );
  };
}

export default Container;
