import React from "react";
import { Link } from "react-router-dom";

class LightInsideLinkButton extends React.Component {
  render() {
    return (
      <Link to={this.props.route}>
        <div className="btn btn-pigier btn-light">{this.props.text}</div>
      </Link>
    );
  }
}

export default LightInsideLinkButton;
