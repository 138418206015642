import React from "react";
import {
  listeannees,
  listepcs,
  listepays,
  listeenseignementsdistance,
  listesituations,
  listediplomes,
  listeprepas,
  listebacs,
  listedepartements,
  listeniveaux,
  listesexes
} from "../config/referentielSiseScolege";
import { FaChevronDown } from "react-icons/fa";
import ListItem from "./ListItem";

class SiseRecapitulatif extends React.Component {
  render() {
    return (
      <>
        <span className="subtitle subtitle--secondary mb0">Enquête SISE</span>
        <h1>Récapitulatif</h1>
        <p className="content mb-5">
          Merci d’avoir répondu à l’enquête. Vous trouvez ci-dessous un
          récapitulatif de vos réponses ainsi que les informations déjà connus
          par votre établissement scolaire. Si toute fois des données sont
          incorrectes, rapprochez-vous de votre assistant pédagogique.
        </p>
        <div id="accordion-1">
          <div
            id="headingOne"
            className="heading-one"
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            1. Informations personnelles
            <FaChevronDown
              style={{
                position: "absolute",
                top: "1rem",
                right: "2rem"
              }}
            />
          </div>
          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion-1"
          >
            <ul className="c-profile-list">
              <ListItem
                title="Numéro INE"
                value={
                  this.props.data != null && this.props.data.numeroIne != null
                    ? this.props.data.numeroIne
                    : ""
                }
              />
              <ListItem
                title="Nom de famille d'usage"
                value={
                  this.props.data != null &&
                    this.props.data.nomFamilleUsage != null
                    ? this.props.data.nomFamilleUsage
                    : ""
                }
              />
              <ListItem
                title="Nom de famille de naissance"
                value={
                  this.props.data != null &&
                    this.props.data.nomFamilleNaissance != null
                    ? this.props.data.nomFamilleNaissance
                    : ""
                }
              />
              <ListItem
                title="Prénom"
                value={
                  this.props.data != null && this.props.data.prenom != null
                    ? this.props.data.prenom
                    : ""
                }
              />
              <ListItem
                title="Prénom 2"
                value={
                  this.props.data != null && this.props.data.prenom2 != null
                    ? this.props.data.prenom2
                    : ""
                }
              />
              <ListItem
                title="Prénom 3"
                value={
                  this.props.data != null && this.props.data.prenom3 != null
                    ? this.props.data.prenom3
                    : ""
                }
              />
              <ListItem
                title="Sexe"
                value={
                  this.props.data.sexe != null &&
                    listesexes.find(o => o.code === this.props.data.sexe) != null
                    ? listesexes.find(o => o.code === this.props.data.sexe)
                      .intitule
                    : ""
                }
              />
              <ListItem
                title="Date de naissance"
                value={
                  this.props.data != null &&
                    this.props.data.dateNaissance != null
                    ? this.props.data.dateNaissance
                    : ""
                }
              />
              <ListItem
                title="Lieu de naissance"
                value={
                  this.props.data != null &&
                    this.props.data.communeNaissance != null
                    ? this.props.data.communeNaissance
                    : ""
                }
              />
              <ListItem
                title="Pays de résidence"
                value={
                  this.props.data != null &&
                    this.props.data.paysResidence != null &&
                    listepays.find(
                      o => o.code === this.props.data.paysResidence
                    ) != null
                    ? listepays.find(
                      o => o.code === this.props.data.paysResidence
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="Nationalité *"
                value={
                  this.props.data != null &&
                    this.props.data.nationalite != null &&
                    listepays.find(o => o.code === this.props.data.nationalite) !=
                    null
                    ? listepays.find(
                      o => o.code === this.props.data.nationalite
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="PCS du parent référent"
                value={
                  this.props.data != null &&
                    this.props.data.pcsParentReferent != null &&
                    listepcs.find(
                      o => o.code === this.props.data.pcsParentReferent
                    ) != null
                    ? listepcs.find(
                      o => o.code === this.props.data.pcsParentReferent
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="PCS de l'autre parent"
                value={
                  this.props.data != null &&
                    this.props.data.pcsParentAutre != null &&
                    listepcs.find(
                      o => o.code === this.props.data.pcsParentAutre
                    ) != null
                    ? listepcs.find(
                      o => o.code === this.props.data.pcsParentAutre
                    ).intitule
                    : ""
                }
              />
            </ul>
          </div>
        </div>
        <div id="accordion-2">
          <div
            id="headingTwà"
            className="heading-two"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            2. Formation actuelle
            <FaChevronDown
              style={{
                position: "absolute",
                top: "1rem",
                right: "2rem"
              }}
            />
          </div>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion-2"
          >
            <ul className="c-profile-list">
              <ListItem
                title="Année d'entrée dans l'établissement *"
                value={
                  this.props.data != null &&
                    this.props.data.anneeEntree != null &&
                    listeannees.find(
                      o => o.code === this.props.data.anneeEntree
                    ) != null
                    ? listeannees.find(
                      o => o.code === this.props.data.anneeEntree
                    ).intitule
                    : this.props.data.anneeEntree
                }
              />
              <ListItem
                title="Niveau dans la formation *"
                value={
                  this.props.data != null &&
                    this.props.data.niveauFormation != null &&
                    listeniveaux.find(
                      o => o.code === this.props.data.niveauFormation
                    ) != null
                    ? listeniveaux.find(
                      o => o.code === this.props.data.niveauFormation
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="Enseignement à distance"
                value={
                  this.props.data != null &&
                    this.props.data.enseignementDistance != null &&
                    listeenseignementsdistance.find(
                      o => o.code === this.props.data.enseignementDistance
                    ) != null
                    ? listeenseignementsdistance.find(
                      o => o.code === this.props.data.enseignementDistance
                    ).intitule
                    : ""
                }
              />
            </ul>
          </div>
        </div>
        <div id="accordion-3">
          <div
            id="headingThree"
            className="heading-three"
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
          >
            3. Parcours académique
            <FaChevronDown
              style={{
                position: "absolute",
                top: "1rem",
                right: "2rem"
              }}
            />
          </div>
          <div
            id="collapseThree"
            className="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordion-3"
          >
            <ul className="c-profile-list">
              <ListItem
                title="Situation année précédente *"
                value={
                  this.props.data != null &&
                    this.props.data.situationAnneePrecedente != null &&
                    listesituations.find(
                      o => o.code === this.props.data.situationAnneePrecedente
                    ) != null
                    ? listesituations.find(
                      o => o.code === this.props.data.situationAnneePrecedente
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="Dernier diplôme obtenu *"
                value={
                  this.props.data != null &&
                    this.props.data.dernierDiplome != null &&
                    listediplomes.find(
                      o => o.code === this.props.data.dernierDiplome
                    ) != null
                    ? listediplomes.find(
                      o => o.code === this.props.data.dernierDiplome
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="Type de classe préparatoire"
                value={
                  this.props.data != null &&
                    this.props.data.typeClassePreparatoire != null &&
                    listeprepas.find(
                      o => o.code === this.props.data.typeClassePreparatoire
                    ) != null
                    ? listeprepas.find(
                      o => o.code === this.props.data.typeClassePreparatoire
                    ).intitule
                    : ""
                }
              />
              <ListItem
                title="Année d'obtention du baccalauréat *"
                value={
                  this.props.data != null &&
                    this.props.data.anneeObtentionBac != null &&
                    listeannees.find(
                      o => o.code === this.props.data.anneeObtentionBac
                    ) != null
                    ? listeannees.find(
                      o => o.code === this.props.data.anneeObtentionBac
                    ).intitule
                    : this.props.data.anneeObtentionBac
                }
              />

              <ListItem
                title="Type de baccalauréat ou équivalence *"
                value={
                  this.props.data != null &&
                    this.props.data.typeBac != null &&
                    listebacs.find(o => o.code === this.props.data.typeBac) !=
                    null
                    ? listebacs.find(o => o.code === this.props.data.typeBac)
                      .intitule
                    : ""
                }
              />
              {this.props.data && this.props.data.typeBac && listebacs.find(
                o => o.code === this.props.data.typeBac && o.type === "Equivalence"
              ) ? <ListItem
                  title="Lieu d'obtention du baccalauréat *"
                  value={
                    this.props.data != null &&
                      this.props.data.departementObtentionBac != null &&
                      listedepartements.find(
                        o => o.code === this.props.data.departementObtentionBac
                      ) != null
                      ? listedepartements.find(
                        o => o.code === this.props.data.departementObtentionBac
                      ).intitule
                      : this.props.data != null &&
                        this.props.data.departementObtentionBac === "099"
                        ? "ETRANGER"
                        : ""
                  }
                /> : null}

            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default SiseRecapitulatif;
