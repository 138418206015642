import React from "react";
import { FaCamera } from "react-icons/fa";
import avatar from "../../assets/img/avatar.jpg"; // with import

class UserPicture extends React.Component {
  render() {
    return (
      <div className="card-img-group">
        <img
          className="card-img-circle"
          src={this.props.photo != null ? this.props.photo : avatar}
          alt=""
        />
        <div className="avataroverlay">
          <button
            className="avataricon"
            onClick={() => {
              window.open(
                "https://outlook.office365.com/ecp/PersonalSettings/EditAccount.aspx?chgPhoto=1&exsvurl=1&realm=eduservices.org",
                "newwindow",
                "width=500,height=450"
              );
              return false;
            }}
          >
            <FaCamera />
          </button>
        </div>
      </div>
    );
  }
}

export default UserPicture;
