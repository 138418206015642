// import { MsalAuthProvider, LoginType } from 'react-aad-msal';

// // Msal Configurations
// const config = {
//   auth: {
//     authority: 'https://login.microsoftonline.com/8a2f18a9-8f0e-4f76-a585-9b07b3a38dea',
//     clientId: 'da0e7c5e-cbca-4237-9b85-5981904e95cf',
//     redirectUri: window.location.origin,
//     // validateAuthority: true,

//     //navigateToLoginRequestUrl: false
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: true
//   }
// };

// // Authentication Parameters
// const authenticationParameters = {
//   scopes: [
//     "directory.accessasuser.all", 
//       "user.read.all", 
//       "mail.read"
//   ]
// }

// // Options
// const options = {
//   loginType: LoginType.Redirect,
//   tokenRefreshUri: window.location.origin + '/auth.html'
// }


// export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)

import { configureMsal } from 'msal-react-v2/lib/AuthProvider'

const Scopes = {
  OPEN_ID: 'openid',
  CUSTOM_SCOPE: 'api://' + process.env.REACT_APP_API_ID + '/appusage.ReadWrite',
  CUSTOM_SCOPE2: 'api://' + process.env.REACT_APP_API_ID + '/enquetes.ReadWrite',
  USER_READ: 'User.Read',
}

const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID, // clientId or appId
    redirectUri: window.location.origin, // redirectUri
    authority: process.env.REACT_APP_AUTHORITY,  // Optional
  }
}


export const {
  AuthProvider,   // Use once!
  getAccessToken, // Can use to inject access tokens elsewhere in your app
  msalClient,     // Use elsewhere for msalClient.logout() and other client functions
  graphClient,    // Use for graph client api calls
  useAccount,     // React Hook to give access to account info in child components
} = configureMsal({
  config,                                                // Required
  msalScopes: [Scopes.OPEN_ID, Scopes.CUSTOM_SCOPE, Scopes.CUSTOM_SCOPE2],     // Optional (But Recommended)
  graphClientScopes: [                                   // Optional (But Recommended
    Scopes.USER_READ,
  ],
})