import React from "react";
import { Link } from "react-router-dom";
import APIs from "../config/apis";

import { getAccessToken } from '../authProvider';

class FaqAside extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faqs: null
    };
  }

  async componentDidMount() {
    var backendAccessToken = await getAccessToken();
    fetch(APIs.find(o => o.app === "backEnd").url + "faq/5", {
      method: "GET",
      headers: {
        Authorization: `Bearer ` + backendAccessToken
      }
    })
      .then((response) => {
        if (response.status === 401) {
          throw new Error(response.status)
        }
        return response
      })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(res => {
        this.setState({ faqs: res });
      })
      .catch(error => {
        window.location.reload();
      });

  }

  handleClickFaq = id => {
    window.scrollTo(0, id);
  };

  render() {
    if (this.state.faqs != null && this.state.faqs.length > 0) {
      return (
        <div className="card card--pigier mb-5">
          <div className="card-body">
            <h3 className="h3">Questions fréquentes</h3>
            <div className="accordion" id="accordion-questionM">
              {this.state.faqs.map((faq, key) => (
                <div className="card border-bottom" key={key}>
                  <div
                    className="card-header mx-minus"
                    id="heading-questionM-1"
                    style={{ padding: 3 }}
                  >
                    <Link
                      onClick={() => this.handleClickFaq(faq.id)}
                      to={"/faq/" + faq.id}
                      className="btn-accordion-aside d-flex justify-content-between align-items-center collapsed"
                      type="button"
                      //data-toggle="collapse"
                      data-target={"#collapse-question-" + faq.id}
                      aria-expanded="false"
                      aria-controls={"#collapse-question-" + faq.id}
                      style={{ width: "100%" }}
                    >
                      <span>{faq.subject}</span>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-right pt-2">
              <Link to="/faq">
                <span
                  className="btn btn-pigier btn-primary position-relative"
                  style={{ padding: 8 }}
                >
                  Voir plus &gt;
                </span>
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
export default FaqAside;
