import React from "react";
import Toast from "./common/Toast";
import apis from "../config/apis";
import apisEndpoints from "../config/apisEndpoints";



import { getAccessToken } from '../authProvider';

class CTASise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        statut: "En attente",
        date: "",
      isScope: false,
      loading: true
    };
  }

  async componentDidMount() {
    var backendAccessToken = await getAccessToken();
    fetch(
      apis.find(o => o.app === "backEnd").url +
      apisEndpoints.find(
        o => o.module === "enquetes" && o.feature === "siseScope"
      ).endpoint,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ` + backendAccessToken
        }
      }
    )
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(res => {
        this.setState({ isScope: res ? res.isScope : false });
        return res ? res.isScope : false;
      })
      .then(res =>
        res
          ? fetch(
            apis.find(o => o.app === "backEnd").url +
            apisEndpoints.find(
              o => o.module === "enquetes" && o.feature === "siseStatus"
            ).endpoint,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ` + backendAccessToken
              }
            }
          )
            .then(res => {
              if (res.ok) {
                return res.json();
              }
            })
            .then(res => {
                this.setState({ statut: res.statut, loading: false, date: res.deadlineSise });
            })
          : null
      );

  }

  render() {
    var text = "";
    if (this.state.statut === "En attente") {
      text =
        `Questionnaire « SISE » non rempli, merci de bien vouloir le compléter avant le ${this.state.date}.`;
    }
    if (this.state.statut === "Incomplet") {
      text =
          `Questionnaire « SISE » incomplet, merci de bien vouloir renseigner tous les champs obligatoires avant le ${this.state.date}.`;
    }
    if (this.state.statut === "Complet") {
      text =
          `Questionnaire « SISE » rempli, vous pouvez revenir consulter et modifier vos informations à tout moment d’ici le ${this.state.date}.`;
    }
    return !this.state.loading && this.state.statut != null &&
      this.state.isScope != null &&
      this.state.isScope ? (
        <Toast text={text} buttonText="Compléter" route="/sise" />
      ) : (
        ""
      );
  }
}
export default CTASise;
