import React from "react";

class SiseEtapeTitre extends React.Component {
  render() {
    return (
      <>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="steps with-title">
                <ul>
                  <li
                    data-step="1"
                    className={this.props.step > 0 ? "step active" : "step"}
                  />
                  <li
                    data-step="2"
                    className={this.props.step > 1 ? "step active" : "step"}
                  />
                  <li
                    data-step="3"
                    className={this.props.step > 2 ? "step active" : "step"}
                  />
                </ul>
              </div>
              <div
                className="steps without-title mt-4"
                style={{ width: "100%", left: 0, transform: "none" }}
              >
                <ul
                  className={this.props.step > 0 ? "level1 active" : "level1"}
                  data-step="1"
                >
                  <li className="level2" />
                </ul>
                <ul
                  className={this.props.step > 0 ? "level1 active" : "level1"}
                  data-step="2"
                >
                  <li className="level2" />
                </ul>
                <ul
                  className={this.props.step > 0 ? "level1 active" : "level1"}
                  data-step="3"
                >
                  <li className="level2" />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <span className="subtitle subtitle--secondary mb0">Enquête SISE</span>
        <h1>{this.props.title}</h1>
      </>
    );
  }
}

export default SiseEtapeTitre;
