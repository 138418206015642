export const APIsEndpoints = [
  {
    module: "enquetes",
    feature: "scolege",
    endpoint: "scolege"
  },
  {
    module: "enquetes",
    feature: "scolegeStatus",
    endpoint: "scolegestatus"
  },
  {
    module: "enquetes",
    feature: "sise",
    endpoint: "sise"
  },
  {
    module: "enquetes",
    feature: "siseStatus",
    endpoint: "sisestatus"
  },
  {
    module: "enquetes",
    feature: "siseScope",
    endpoint: "sisescope"
  }
];

export default APIsEndpoints;
