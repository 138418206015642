import React from "react";

class SiseIntro extends React.Component {
  render() {
    return (
      <>
        <h1>
          Bienvenue
          <span className="subtitle">Enquête SISE</span>
        </h1>
        <p className="content">
          Le ministère de l’Education Nationale lance chaque année une enquête
          de recensement auprès des étudiants de l’enseignement supérieur.
        </p>
      </>
    );
  }
}

export default SiseIntro;
