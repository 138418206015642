import React from "react";

class ListItem extends React.Component {
  render() {
    return (
      <li className="c-profile-list__item">
        <div className="row">
          <div className="col-12 col-md-3">
            <strong> {this.props.title}</strong>
          </div>
          <div className="col-12 col-md-9">
            <p>{this.props.value}</p>
          </div>
        </div>
      </li>
    );
  }
}

export default ListItem;
